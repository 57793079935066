.heroCarousel {
    position: relative;
    /* width: 800px; */
}

.heroCarousel .swiper-slide img {
    width: 100%;
    height: 100%;
    aspect-ratio: 8 / 4;
    object-fit: cover;
    object-position: center;
}

.heroCarousel .swiper-pagination .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0px 3px 10px #838383;
    opacity: 1;
    margin: 0 8px !important;
}

.heroCarousel .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #52a1ff;
    border-color: #52a1ff;
}

@media (max-width: 480px) {
    .heroCarousel .swiper-slide img {
        aspect-ratio: 8 / 3;
    }

    .heroCarousel .swiper-button-prev:after,
    .heroCarousel .swiper-button-next:after {
        font-size: 24px;
    }
}
