.favoriteCheck {
    width: 40px;
    height: 45px;
    background: rgb(0, 0, 0, 20%);
    border-top-left-radius: 3px;
    position: relative;
}
.favoriteCheck span {
    color: white;
    font-size: 18px;
}

.favoriteCheck input {
    display: none;
}

.favoriteCheck label {
    color: #fff;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin: 0;
    margin-top: 0px !important;
    user-select: none;
    display: flex;
    justify-content: center;
}

.favoriteCheck label:hover {
    color: grey;
}

.favoriteCheck label::selection {
    color: none;
    background: transparent;
}

.favoriteCheck label::moz-selection {
    color: none;
    background: transparent;
}

.favoriteCheck input:checked + label {
    color: var(--primColor);
}

@media (max-width: 500px) {
    .favoriteCheck span {
        font-size: 16px;
    }
}
