.liveAuction {
    margin: 30px auto;
}

.liveAuction .liveAucImages {
    width: 50%;
}

.liveAuction .liveAucImages .image-gallery {
    max-height: 100%;
}

.liveAuction .auctionAssetsCnt {
    width: 100%;
    justify-content: space-between;
    gap: 48px;
    flex-wrap: nowrap;
}

.liveAuction .liveAucImages .image-gallery-content.left .image-gallery-slide .image-gallery-image,
.liveAuction .liveAucImages .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    height: 400px;
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
}

.liveAuction
    .liveAucImages
    .image-gallery-content.left.fullscreen
    .image-gallery-slide
    .image-gallery-image {
    max-height: -webkit-fill-available;
    height: 100%;
}

.liveAuction .liveAucImages .image-gallery-content.left.fullscreen .image-gallery-slides {
    background: initial;
}

.liveAucLoader.grid .loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

/* .liveAuction .liveAucImages .image-gallery-icon {
    width: 30px;
    height: 30px;
    color: #111;
    padding: 0;
    filter: none;
} */

.liveAuction .liveAucImages .image-gallery-left-nav .image-gallery-svg,
.liveAuction .liveAucImages .image-gallery-right-nav .image-gallery-svg {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #ebebeb;
    padding: 5px;
}

.liveAuction .liveAucImages .image-gallery-left-nav {
    left: 10px;
}

.liveAuction .liveAucImages .image-gallery-right-nav {
    right: 10px;
}

.liveAuction .image-gallery-thumbnails {
    margin-top: 15px;
}

.liveAuction .liveAucImages .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 75px;
    line-height: 0;
    height: 52px;
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 5px;
}

.liveAuction .liveAucImages .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border: 2px solid transparent;
}

.liveAuction .liveAucImages .image-gallery-thumbnail,
.liveAuction .liveAucImages .image-gallery-thumbnail.active,
.liveAuction .liveAucImages .image-gallery-thumbnail:hover,
.liveAuction .liveAucImages .image-gallery-thumbnail:focus {
    width: 75px;
    border: none;
}

.liveAuction .liveAucImages .image-gallery-thumbnail:hover .image-gallery-thumbnail-image,
.liveAuction .liveAucImages .image-gallery-thumbnail:focus .image-gallery-thumbnail-image {
    border: 2px solid var(--primColor);
}

.liveAuction .liveAucImages .image-gallery-thumbnails-wrapper.left,
.liveAuction .liveAucImages .image-gallery-thumbnails-wrapper.right {
    width: 75px;
}

.liveAuction .liveAucImages .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 400px;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
}

.liveAuction .image-gallery-slide-wrapper.left,
.liveAuction .image-gallery-slide-wrapper.right {
    height: 400px;
}

.liveAuction .liveAucImages .image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: start;
    margin-bottom: 25px;
}

.liveAuction .searchResults {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 15px;
    gap: 15px; */
}

.liveAuction .pvTimerCnt {
    position: sticky;
    top: -10px;
    z-index: 10;
    background: transparent;
    padding: 10px 0;
    margin-bottom: 16px;
}

.liveAuction .pvTimerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primColor);
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;
    background: #fff;
}

.liveAuction .pvTimerBox > * {
    margin-bottom: 0;
}

.liveAuction .pvTimerBox label {
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    font-size: 15px;
}

.liveAuction .pvTimerBox h4 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.liveAuction .pvTimerBox .material-icons-outlined {
    padding-inline-end: 10px;
}

.videoAuction {
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.videoAuction h5 {
    font-size: 16px;
    color: #373737;
    font-weight: 600;
    width: 100%;
}

.liveAuction .laInfo {
    padding: 0;
    margin: 0;
    text-align: start;
    list-style-type: none;
    margin-top: 45px;
}

.liveAuction .laInfo li {
    margin-bottom: 15px;
}

.liveAuction .laInfo li > label {
    font-size: 15px;
    font-weight: 600;
    color: #373737;
    padding-inline-end: 15px;
    margin-bottom: 0;
}

.liveAuction .laInfo li label > span {
    font-size: 15px;
    color: #646464;
}

.liveAuction .laInfo li.descriptionInfo span {
    display: block;
    width: 100%;
    line-height: 22px;
}

.liveAuction .pvAccordian .MuiButtonBase-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.liveAuction .pvAccordian .MuiCollapse-container {
    text-align: start;
}

.liveAuction .laTitle {
    font-size: 20px;
    font-weight: 700;
    text-align: start;
    /* text-transform: uppercase; */
    margin: 25px 0 10px 0;
    color: #333333;
}

.liveAuction .productView {
    font-size: 24px;
    color: #0e131f;
    margin: 0 0 20px 0;
    font-weight: 700;
    text-align: start;
}

.productViewInvite .productView .pvLt {
    width: 40%;
}
.productViewInvite .productView .pvRt {
    width: 60%;
}

.liveAuction .searchResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.liveAuction .auctionPageImg {
    width: 100%;
    height: 400px;
    object-fit: contain;
    object-position: top;
    border-radius: 15px;
}

.fltrLeftDrawer .MuiDrawer-paper {
    max-width: 400px;
    padding: 20px;
}

.liveAuction .searchMiscFilters .customSelect {
    margin-bottom: 0;
}
.liveAuction .searchMiscFilters .customSelect .MuiSelect-select.MuiSelect-select {
    height: 50px !important;
    padding: 0px 32px 0px 24px !important;
}

.liveAuction .searchMiscFilters .fltrBtn {
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding-left: 22px;
    padding-right: 22px;
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.liveAuction .searchMiscFilters .fltrBtn .material-icons {
    margin-left: auto !important;
}

.fltrLeftDrawer .filterCheck {
    flex-direction: column;
    align-items: flex-start !important;
}

.csvRdrWrpr > div {
    border-radius: 5px !important;
    min-height: 120px;
    background: whitesmoke;
}

.csvPopWrpr .csvRdrWrpr > div > div {
    border-radius: 5px !important;
    background: rgb(215 230 255) !important;
    border: 1px dashed var(--primColor);
}

.csvPopWrpr .csvRdrWrpr > div > div > div > span {
    color: var(--primColor) !important;
    background: transparent !important;
}

.csvPopWrpr .csvTable .table td input {
    width: 100%;
    height: 45px;
    padding: 10px;
}

.csvPopWrpr .csvTable .table td input:focus {
    outline: none;
}

.csvPopWrpr .primButton button {
    max-width: 200px;
    margin: auto;
}

.descriptionContainer {
    max-height: 209px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

.descriptionContainer.expandedContainer {
    max-height: unset;
}

.readMoreBtn .MuiButton-label {
    color: var(--primColor);
}

.liveAuction .MuiPaper-root.MuiAccordion-root {
    margin-bottom: 10px;
    border: none !important;
    background: #f6f6f6 !important;
    color: #333333;
}

.liveAuction .MuiAccordionSummary-content.Mui-expanded,
.liveAuction .Mui-expanded .MuiIconButton-label .MuiSvgIcon-root {
    color: var(--primColor);
    fill: var(--primColor) !important;
}

.liveAuction .MuiButtonBase-root.MuiAccordionSummary-root {
    margin-bottom: 0;
    background: #fff;
}

.liveAuction .MuiAccordionDetails-root {
    font-size: 15px;
    padding-left: 17px;
    padding-right: 17px;
}

.liveAuction .accTitle {
    margin-bottom: 0;
    font-weight: 600;
}

.liveAuction .MuiAccordionDetails-root h6 {
    padding: 10px;
    border-radius: 4px;
    background: white;
}

.liveAucLoader {
    margin-top: 30px;
    margin-bottom: 30px;
}

.liveAucLoader .loadingCnt {
    display: block;
}

.liveAucLoader .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.actnDtlWrpr {
    width: 50%;
}

.actnDtlWrpr .acContent .acMiscInfo:not(:last-child) {
    margin-bottom: 15px;
}
.actnDtlWrpr .acContent .acMiscInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
}

.actnDtlWrpr .acContent .acMiscInfo p {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin: 0;
    font-size: 15px;
    font-family: var(--soleilBook);
    color: #96989c;
    width: 180px;
    min-width: 180px;
}

.actnDtlWrpr .acContent .acMiscInfo p span {
    padding-right: 10px;
}

.liveAuction .actnTmrDtl .acTimer .material-icons-outlined {
    font-size: 60px;
}

.liveAuction .actnTmrDtl {
    color: #646464;
}

.liveAuction .actnTmrDtl.acActBtn .atcBtn .MuiButton-label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.liveAuction .headerSearchForm input {
    height: 45px;
    border-radius: 25px;
    width: 100%;
    padding-right: 40px;
    border-color: #e3dfdf;
}

.liveAuction .headerSearchForm .btn {
    position: absolute;
    right: 0px;
    border-radius: 22px;
    padding: 2px;
    cursor: pointer;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: var(--secColor);
    color: white;
    min-width: unset;
}

.liveAuction .headerSearchForm {
    max-width: 400px;
    width: 100%;
}

.liveAuction .auctionPageImg {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

.liveAuction .pvTimerBox {
    background: var(--primColor);
    border-radius: 10px;
}

.liveAuction .pvTimerBox * {
    color: var(--secColor);
}

.liveAuction .pvTimerBox h1 {
    font-size: 16px;
    font-weight: 400;
}

.liveAuction .liveAucTitleCnt,
.liveAuction .ctaInfopCnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.liveAuction .liveAucTitleCnt p {
    margin-bottom: 0px;
    padding: 12px 16px;
    background: var(--accentColorSec);
    border-radius: 10px;
    font-size: 14px;
}
.liveAuction .liveAucTitleCnt p::first-letter {
    text-transform: uppercase;
}

.liveAuction .ctaInfopCnt .acActBtn.actnTmrDtl {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.liveAuction .ctaInfopCnt .pvInfoCnt {
    background: #f9fbfd;
    padding: 16px 12px;
}
.liveAuction .ctaInfopCnt .pvInfoCnt .acMiscInfo p,
.liveAuction .ctaInfopCnt .pvInfoCnt .acMiscInfo span {
    font-size: 16px;
    color: var(--secColor);
}
.liveAuction .ctaInfopCnt .pvInfoCnt .acMiscInfo p {
    font-weight: 400;
}
.liveAuction .ctaInfopCnt .pvInfoCnt .acMiscInfo span {
    font-weight: 600;
}

.liveAuction .ctaInfopCnt .acActBtn.actnTmrDtl h1 {
    font-size: 18px;
    color: var(--secColor);
    font-weight: 400;
}
.liveAuction .ctaInfopCnt .acActBtn.actnTmrDtl button {
    border-radius: 15px;
}

@media (max-width: 1024px) {
    .liveAuction {
        margin: 10px auto;
    }
    .liveAuction .pvTimerCnt {
        top: 90px;
    }
    .videoAuction {
        height: 100%;
        min-height: 400px;
    }

    .liveAuction .searchMiscFilters .customSelect {
        display: block !important;
    }

    .liveAuction .searchMiscFilters {
        display: flex !important;
        margin-bottom: 0 !important;
        margin-left: 15px;
    }
}

@media (max-width: 991px) {
    .auctionAssetsCnt {
        flex-direction: column;
    }
    .auctionAssetsCnt .liveAucImages {
        /* margin-top: 30px; */
    }
}

@media (max-width: 800px) {
    .liveAuction .searchMiscFilters {
        justify-content: space-between !important;
        margin: 15px;
    }

    .liveAuction .srcFltrWpr {
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
}

@media (max-width: 500px) {
    .actnDtlWrpr .acContent .acMiscInfo {
        margin-bottom: 15px;
    }
    .liveAuction .ctaInfopCnt .pvInfoCnt {
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .liveAuction .ctaInfopCnt .pvInfoCnt .acMiscInfo p,
    .liveAuction .ctaInfopCnt .pvInfoCnt .acMiscInfo span {
        font-size: 13px;
        color: var(--secColor);
    }
    .liveAuction .auctionPageImg {
        height: 100%;
    }
    .auctionAssetsCnt {
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .liveAuction .liveAucImages,
    .actnDtlWrpr {
        width: 100%;
    }
    .liveAuction .ctaInfopCnt {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .acContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .productCardGrid .primButton.small button,
    .productCardGrid .secButton.small button {
        width: 80%;
    }
    .liveAuction .pvTimerCnt {
        top: 70px;
        margin-bottom: 10px;
        width: 100%;
    }
    .liveAuction .pvTimerBox h4 {
        font-size: 14px;
    }
    .videoAuction {
        min-height: 300px;
    }
    .liveAuction .liveAucImages .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 300px;
    }
    .image-gallery-fullscreen-button .image-gallery-svg {
        height: 24px;
        width: 24px;
    }
    .liveAuction .searchMiscFilters .fltrBtn {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .liveAuction .upldBlkWrpr .mnfstBtn.upldBdnBtn {
        margin-top: 15px;
    }

    .liveAuction .searchMiscFilters {
        flex-wrap: wrap;
    }

    .liveAuction .searchMiscFilters > div {
        width: 100% !important;
        margin-top: 15px !important;
        margin-right: 0 !important;
    }
    .liveAuction .upldBlkWrpr .mnfstBtn {
        width: 100%;
        display: block;
    }
    .liveAuction .actnTtrl {
        font-size: 22px;
        margin-bottom: 0 !important;
    }
    .liveAuction .liveAucTitleCnt,
    .liveAuction .pvTimerBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .liveAuction .pvTimerBox h1 {
        font-size: 14px;
    }
    .liveAuction .pvTimerBox {
        padding: 15px 12px;
    }

    .liveAuction .ctaInfopCnt .acActBtn.actnTmrDtl {
        margin-top: 16px;
    }
}

@media (max-width: 445px) {
    .liveAuction .pvTimerBox h4 {
        letter-spacing: 0;
    }

    .actnDtlWrpr .acContent .acMiscInfo {
        flex-wrap: wrap;
    }

    /* .actnDtlWrpr .acContent .acMiscInfo > span {
        width: 100%;
        padding-top: 10px;
      
    } */

    .actnDtlWrpr .acContent .acMiscInfo p {
        width: 100%;
    }
    .fltrLeftDrawer .MuiDrawer-paper {
        max-width: 300px;
        padding: 15px;
    }

    .filterPanel .MuiAccordionDetails-root {
        padding: 0px 0px 10px 0px !important;
    }
}
