.filterPanel {
    padding-left: 8px;
}

.filterPanel .fpTitle {
    text-align: left;
    font-size: 20px;
    color: var(--secColor);
    font-weight: 400;
}

.filterPanel .MuiPaper-root.MuiAccordion-root {
    border: none;
    margin-bottom: 5px;
}

.filterPanel .accTitle {
    color: var(--secColor);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;
}
.filterPanel .priceInput .customInput .MuiInputBase-input {
    font-size: 12px;
}

.filterTitleCnt {
    margin-bottom: 15px;
    margin-top: 10px;
}

.filterPanel h4 {
    font-size: 20px;
    color: var(--secColor);
    font-weight: 400;
}

.filterPanel .filterClear {
    text-transform: none;
    color: var(--accentColorPrim);
    font-size: 12px;
}

body.arabic .filterPanel .filterClear {
    margin-right: auto;
    margin-left: 0 !important;
}

.filterPanel .filterClear .material-icons {
    padding-inline-end: 5px;
    font-size: 18px;
}

body.arabic .filterPanel .filterClear .material-icons {
    padding-inline-start: 5px;
}

.filterPanel .MuiAccordionDetails-root {
    padding: 0px 0px;
}
.filterPanel .MuiCollapse-root {
    margin-top: 8px;
}
.filterPanel .MuiListItem-button:hover {
    background: white;
}

.filterPanel .filterAcc .customCheckbox .Mui-checked .MuiIconButton-label {
    color: var(--accentColorPrim);
}

.filterPanel .filterCheck .customCheckbox,
.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row {
    width: 100%;
}

.filterPanel .filterCheck .MuiFormControlLabel-root,
.filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label,
.filterPanel .filterCheck .MuiListItem-button {
    width: 100%;
    margin: 0;
    font-family: var(--fontFamily);
}

.filterPanel .filterCheck .MuiListItem-button {
    padding: 0;
    font-size: 14px;
}

.RadioBox .MuiListItem-button {
    padding: 0;
}

.RadioBox .MuiAccordionDetails-root {
    padding-top: 0;
}

.filterPanelSkeleton {
    /* height: 60vh; */
    margin-bottom: 30px;
}

.filterPanel .filterCheck {
    width: 100%;
}

.filterPanel .priceSlider {
    width: 100%;
}

.filterPanel .priceSlider h5 {
    font-size: 15px;
    text-align: left;
    color: #2b2b2b;
    font-weight: 600;
}

.filterPanel .priceSlider h5 span {
    padding: 0 5px;
}

.filterPanel .filterAcc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.filterPanel .filterAcc .MuiAccordion-root {
    border: 1px solid #d9e4f2;
    border-radius: 12px;
    padding: 20px;
    width: 100%;
}

.filterPanel .filterAcc .MuiAccordionSummary-content {
    margin: 0px;
}
.filterPanel .filterAcc .MuiAccordionSummary-root {
    padding: 0px;
    min-height: 0px;
}
.filterPanel .filterAcc .MuiAccordionSummary-root h6 {
    font-size: 16px;
    font-weight: 400;
}
.filterPanel .filterAcc .MuiAccordionSummary-root .MuiIconButton-root {
    padding: 0px;
}
.filterPanel .filterAcc .MuiAccordionSummary-root .MuiSvgIcon-root {
    color: var(--accentColorPrim);
}
.filterPanel .filterAcc .customCheckbox input[type='checkbox'] {
    color: var(--accentColorPrim);
}

.filterPanel .filterAcc .customCheckbox .MuiIconButton-label {
    color: #d9e1e7;
}

/* .filterPanel .filterAcc .customCheckbox .MuiIconButton-label .MuiSvgIcon-root {
    color: var(--accentColorPrim);
} */
