.cart.returnCart {
    background: var(--backgroundColor);
    padding-top: 30px;
    padding-bottom: 30px;
}

.cart.returnCart .cartLt .cartHead {
    margin-bottom: 15px;
}
.cart.returnCart .cartProducts {
    margin-bottom: 30px;
}

.cart.returnCart .cartLt .cartHead h1 {
    font-size: 25px;
    color: #353535;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

.cart.returnCart .cartLt .cartHead span {
    font-size: 14px;
    color: #999999;
    margin-inline-start: 10px;
    font-weight: 700;
}

.cart.returnCart .cartLt {
    width: 100%;
}

.returnLt {
    width: 100%;
}

.cart.returnCart .cartRt {
    width: 30%;
    text-align: left;
}

.cart.returnCart .cartRt .cartSummary {
    background: #ffffff;
    padding: 15px;
    position: sticky;
    top: 15px;
}

.cart.returnCart .cartRt .cartSummary h3 {
    font-size: 16px;
    color: #595959;
    font-weight: 600;
    margin-bottom: 5px;
}

.cart.returnCart .cartRt .cartSummary h5 {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 600;
    margin: 0px;
    padding-inline-start: 10px;
    display: inline;
}

.cart.returnCart .cartRt .cartSummary > label {
    font-size: 15px;
    color: #595959;
    margin: 0px;
}

.cart.returnCart .cartRt .cartSummary .primButton {
    margin-top: 20px;
}

.cart.returnCart .emptyCart {
    width: 500px;
    margin: 20px auto;
}

.cart.returnCart .emptyCart object {
    width: 160px;
}

.cart.returnCart .emptyCart h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 15px;
}

.cart.returnCart .emptyCart .primButton {
    width: max-content;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .cart.returnCart .cartCnt {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .cart.returnCart .cartLt,
    .cart.returnCart .cartRt {
        width: 100%;
    }
    .cart.returnCart .cartRt {
        margin-bottom: 20px;
    }
    .cart.returnCart .cartRt .cartSummary {
        display: flex;
        justify-content: space-between;
    }

    .cart.returnCart .cartRt .cartSummary .primButton {
        margin-top: 0;
    }
    .cart.returnCart .cartLt .cartHead h1 {
        font-size: 22px;
    }
}

@media (max-width: 500px) {
    .cart.returnCart {
        padding-bottom: 0;
    }
    .cart.returnCart .cartProducts {
        margin-bottom: 0;
    }
    .cart.returnCart .cartLt .cartHead h1 {
        font-size: 20px;
    }

    .cartSummary {
        flex-wrap: wrap;
    }

    .cartSummary > div {
        width: 100%;
    }

    .cartSummary h5 {
        float: right;
    }

    .cartSummary .primButton {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .cart.returnCart .cartLt .cartHead h1 {
        font-size: 18px;
    }
}
