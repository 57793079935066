:root {
    /* --fontFamily: 'Kumbh Sans'; */
    --fontFamily: 'Inter';
    --fontFamilySec: 'Caudex';
    /* --primColor: #6f9ace; */
    --primColor: rgb(224, 179, 91);
    /* --secColor: rgb(43, 43, 43); */
    --secColor: rgb(5, 21, 39);
    /* --accentColor: rgb(151, 151, 151); */
    --accentColorSec: #dfedff;
    --accentColorPrim: #3f76b8;
    --greyColor: rgb(186, 177, 177);
    --backgroundColor: #fff;
}

.primButton button {
    color: #fff;
    border-radius: 3px;
    background-color: var(--secColor);
    /* border: 1px solid var(--primColor); */
    transition: 200ms all ease-in-out;
    box-shadow: none;
}

.primButton button:hover {
    background-color: var(--secColor);
    /* border: 1px solid var(--primColor); */
    color: #fff;
    opacity: 0.9;
    box-shadow: none;
}

/* .secButton button {
    color: var(--primColor);
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid var(--primColor);
    transition: 200ms all ease-in-out;
    box-shadow: none;
} */
.secButton button {
    color: #fff;
    height: fit-content;
    width: fit-content;
    padding: 12px 24px;
    border-radius: 15px;
    background-color: var(--secColor);
    /* border: 1px solid var(--primColor); */
    /* transition: 200ms all ease-in-out; */
    box-shadow: none;
}

/* .secButton button:hover {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: #fff;
    box-shadow: none;
} */

.primButton button,
.secButton button {
    height: 50px;
}

.primButton.small button,
.secButton.small button {
    height: 48px;
    min-width: max-content;
}
.primButton.small button:hover,
.secButton.small button:hover {
    background: var(--secColor);
}

.primButton.large button,
.secButton.large button {
    height: 60px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #4e4e4e;
    background: #ffddd6;
}

::selection {
    color: #4e4e4e;
    background: #ffddd6;
}

@media (max-width: 500px) {
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        height: 40px;
        font-size: 13px;
    }
}
