.dashboard {
    background: var(--backgroundColor);
    padding-top: 30px;
    padding-bottom: 30px;
}

.sideNav {
    background: #fff;
    padding: 10px;
}

.sideNav h5 {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    text-align: left;
    padding-top: 15px;
}

.sideNav ul {
    padding-inline-start: 0;
    margin: 0;
}

.sideNav ul a,
.sideNav ul .MuiListItem-button {
    width: 100%;
    height: 100%;
    text-transform: capitalize;
    display: block;
    color: #979797 !important;
    font-size: 15px;
    font-weight: 600;
    display: flex;
}

body.arabic .sideNav ul a,
body.arabic .sideNav ul .MuiListItem-button {
    text-align: right;
}

.sideNav ul a span.material-icons,
.sideNav ul .MuiListItem-button span.material-icons {
    padding-inline-end: 10px;
}

body.arabic .sideNav ul a span.material-icons,
body.arabic .sideNav ul .MuiListItem-button span.material-icons {
    padding-inline-start: 10px;
    padding-inline-end: 0;
}

.sideNav ul .MuiCollapse-wrapperInner {
    padding-inline-start: 35px;
}

.sideNav ul a:hover {
    text-decoration: none;
}

.sideNav ul a.active,
.sideNav ul .activeDropdown {
    color: var(--secColor) !important;
}

.sideNav .profileImgCnt {
    margin-left: 0px;
    margin-right: 10px;
}

body.arabic .sideNav .profileImgCnt {
    margin-left: 10px;
    margin-right: 0px;
}

.dashboard .productCardGrid {
    margin-inline-end: 10px;
}

.dashboard .dashboardLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.dashboard .dashboardLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.dashboard .dashboardLt .gridListToggle i {
    padding-inline-end: 10px;
}

.dashTitle {
    font-size: 20px;
    font-weight: 700;
    color: #353535;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.dashTitle span {
    font-size: 14px;
    font-weight: 600;
    color: #999999;
    padding-inline-start: 10px;
    text-transform: initial;
}

.myAccount .accountCnt {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    width: 100%;
}
.myAccount .accChild h4 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    margin: 24px 0;
    color: var(--secColor);
}

.myAccount .accChild {
    text-align: left;
}

body.arabic .myAccount .accChild {
    text-align: right;
}

body.arabic .myAccount .accChild .customCheckbox .MuiFormControlLabel-root {
    margin-right: 0;
}

.myAccount .accChild .primButton button {
    min-width: 150px;
}

.myAccount .profileAct {
    width: 100%;
}

.myAccount .primButton {
    max-width: 200px;
    margin: 20px auto;
    margin-inline-start: 0;
    margin-inline-end: auto;
}

.mySavedCards .cardActBtn button:first-child {
    margin-inline-end: 10px;
}

.mySavedCards .cardHead {
    margin-bottom: 20px;
}

.mySavedCards .cardHead button {
    color: var(--primColor);
    border-color: var(--primColor);
}

.mySavedCards button span.material-icons {
    padding-inline-end: 10px;
    font-size: 20px;
}

.mySavedCards .modal-dialog.modal-lg {
    max-width: 500px !important;
}

.mySavedCards .modal-dialog.modal-lg .primButton {
    width: 200px;
    margin: 0 auto;
}

.mySavedCards ::-webkit-datetime-edit {
    color: transparent;
}
.mySavedCards :focus::-webkit-datetime-edit {
    color: #000;
}

.mySavedCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
}

.mySavedCards .emptySavedCard {
    border: 1px dashed#A6ADB4;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
}

.mySavedCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 15px;
}

.mySavedCards .emptySavedCard {
    border: 1px dashed#A6ADB4;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
}

.filledSavedCard .sc-primary-label {
    background: #f38723;
    padding: 5px 15px;
    padding-right: 25px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-left: 9px;
    /* position: absolute;
    top: 42px;
    right: 0; */
    font-size: 14px;
}

.mySavedCards .filledSavedCard {
    height: 100%;
    min-height: 175px;
    border-radius: 15px;
    /* background: url('../../assets/images/lgtbg.png'); */
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(47, 47, 47, 1) 25%,
        rgba(102, 102, 102, 1) 73%,
        rgba(89, 88, 88, 1) 100%
    );
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 1rem 0.75rem;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}

.mySavedCards .modifyCard {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.mySavedCards .modifyCard .material-icons {
    font-size: 18px;
}

.mySavedCards .modifyCard.edit {
    background: #5d80ff9c;
    color: #fff;
}

.mySavedCards .modifyCard.delete {
    background: #ff5d5d9c;
    color: #fff;
    margin-inline-start: 10px;
}

.mySavedCards .card-num {
    padding: 18px 0 15px;
    text-align: center;
}

.mySavedCards .card-num span {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1;
    margin: 5px 0;
}

.mySavedCards .card-num span + span {
    margin-inline-start: 15px;
}

.mySavedCards .fs-12 {
    font-size: 0.75rem;
    text-align: start;
    margin-top: 10px;
}
.dashboardInner.myInvoices .auctionStatus {
    display: none;
}

.auctionStatus {
    display: flex;
    flex-wrap: wrap;
}

.auctionStatus .MuiListItem-root {
    padding: 0;
    width: max-content;
    margin-bottom: 25px;
    border-radius: 50px;
}

.auctionStatus .MuiListItem-root a {
    padding: 10px 25px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    color: #000;
    height: 45px;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
}

.auctionStatus .MuiListItem-root a:focus,
.auctionStatus .MuiListItem-root a:hover {
    text-decoration: none;
}

.auctionStatus .MuiListItem-root:not(:last-child) {
    margin-inline-end: 16px;
}

.auctionStatus .MuiListItem-root a.active {
    background: rgb(229 237 248);
    color: var(--primColor);
    border: 1px solid var(--primColor);
}

.dashboard .dashboardResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 15px;
    row-gap: 25px;
    margin: 0;
}

.dashboard .dashSortActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 10px 0 20px;
}

.dashboard .dashSortActions .customSelect {
    margin-bottom: 0;
    margin-inline-start: auto;
    min-width: max-content;
    width: 100%;
}

.dashboard .dashSortActions .MuiSelect-root {
    font-size: 14px;
    height: 20px;
}

.dashboard .walletHead {
    margin-bottom: 30px;
}

.dashboard .walletBalance {
    background: #f8fbff;
    border: 1px dashed var(--primColor);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--primColor);
    text-align: start;
    margin-inline-end: auto;
    width: max-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard .walletBalance .material-icons {
    padding-inline-end: 5px;
}

.dashboard .walletBalance span:last-child {
    padding-inline-start: 10px;
}

.dashboard .walletHead button .material-icons {
    margin-right: 8px;
}

.mySavedCards .modal-dialog .addCardModalActions {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 20px;
}

.mySavedCards .modal-dialog .addCardModalActions .primButton {
    margin-bottom: 10px;
}

.mySavedSearches .table thead th {
    text-transform: capitalize;
    font-weight: 600;
}

.mySavedSearches .table thead th:last-child {
    text-align: center;
}

.dashboardRt .toggleRespDrawer {
    display: none;
}

.dashboard .notifTable input {
    color: var(--accentColorPrim);
}
.dashboard .notifTable .MuiCheckbox-root {
    color: #d9e1e7;
}
.dashboard .notifTable .Mui-checked.MuiCheckbox-root {
    color: var(--accentColorPrim);
}

.dashboard .notifTable h6 {
    text-align: left;
    margin: 10px 0 15px;
    font-weight: 600;
}
.dashboard .notifTable .MuiListItem-button {
    font-family: var(--fontFamily);
    font-size: 14px;
    padding-left: 0;
}
.dashboard .notifTable .MuiListItem-button:hover {
    background: transparent;
}

.dashboard .maDivider {
    margin: 30px 0;
}

.dashboard .notifTable .notifElements .customCheckbox {
    border: 1px solid #e4e4e4;
    padding: 10px 15px;
    margin-top: -1px;
}

.addPointsDialog .MuiTypography-h6 {
    font-weight: 600;
}
.addPointsDialog p {
    margin-bottom: 6px;
    display: grid;
    font-size: 14px;
    grid-template-columns: 120px 1fr;
}
.addPointsDialog p span {
    margin-left: 4px;
    font-weight: 500;
}

.mySavedCards .wpwl-form-card {
    background: transparent;
    box-shadow: none;
    border: 0;
}
.mySavedCards .wpwl-form-card * {
    text-align: left;
}
.mySavedCards .wpwl-form-card input {
    text-align: left;
    background: #f5f8fc;
    border-radius: 4px;
}
.mySavedCards .wpwl-form-card input::placeholder {
    font-size: 14px;
    color: var(--secColor);
}
.mySavedCards .wpwl-form-card button {
    background: var(--secColor) !important;
    color: white !important;
    border-radius: 10px !important;
    text-transform: none !important;
}

.cardViewDialog form .primButton button {
    margin-left: 16px;
    border-radius: 15px;
}
.cardViewDialog form .primButton button:hover,
.cardViewDialog .secButton button:hover {
    background: var(--secColor);
}

@media (max-width: 1024px) {
    .respSort {
        display: none;
    }
    /* .auctionStatus {
        display: none;
    } */
    .dashboardRt .toggleRespDrawer {
        display: inline-flex;
    }
    .dashboard .dashSortActions {
        display: none;
    }
    .dashboardInner.myInvoices .auctionStatus {
        display: flex;
    }
}

@media (max-width: 600px) {
    .mySavedCards {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
}

@media (max-width: 545px) {
    .dashboard .dashSortActions .customSelect {
        min-width: -moz-fit-content;
        min-width: fit-content;
        width: auto;
    }
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        height: 40px;
        font-size: 15px;
        line-height: 1.2;
    }
    .dashboard .walletBalance span:last-child {
        margin-left: auto;
    }
}

@media (max-width: 470px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 15px;
        height: 40px;
        font-size: 13px;
        line-height: 1.4;
    }
    .dashboard .walletHead .walletBalance {
        width: 100%;
        margin-bottom: 10px;
    }

    .dashboard .walletHead button {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }
}

@media (max-width: 385px) {
    .auctionStatus .MuiListItem-root a {
        padding: 10px 19px;
        font-size: 13px;
    }
    .auctionStatus .MuiListItem-root:not(:last-child) {
        margin-inline-end: 5px;
    }
    .auctionStatus {
        flex-wrap: wrap;
    }
}

@media (max-width: 340px) {
    .auctionStatus .MuiListItem-root a {
        font-size: 12px;
    }
    .auctionStatus .MuiListItem-root {
        margin-bottom: 15px;
    }
}
