* {
    margin: 0;
    border: 0;
    outline: 0;
}

body {
    margin: 0;
    /* font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', */
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--backgroundColor);
    color: var(--secColor);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorPointer {
    cursor: pointer;
}

.customContainer {
    padding-inline-start: 80px !important;
    padding-inline-end: 80px !important;
    width: 100% !important;
    max-width: 1600px;
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

header,
footer {
    background: #fff;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.secFont {
    font-family: var(--fontFamilySec), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 30px;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--accentColorPrim) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root,
.customTextArea .MuiFormControl-root {
    width: 100%;
    margin: 0;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.home .homeCnt,
.search .searchCnt {
    margin-top: 15px;
}

.search .sectionTitle {
    margin-bottom: 20px;
}

.home .homeLt,
.search .searchLt,
.dashboard .dashboardLt {
    width: 23%;
    margin-inline-end: 30px;
    max-width: 350px;
}

.home .homeRt,
.search .searchRt,
.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: 78%;
}

.home .homeLt .searchInput .input-group-text,
.search .searchLt .searchInput .input-group-text,
.dashboard .dashboardLt .searchInput .input-group-text {
    background-color: #fff;
    border-right: none;
}

.home .homeLt .searchInput input,
.search .searchLt .searchInput input,
.dashboard .dashboardLt .searchInput input {
    border-left: none;
}

.home .homeLt .searchInput input:focus,
.search .searchLt .searchInput input:focus,
.dashboard .dashboardLt .searchInput input:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.search .sectionTitle h2 {
    font-size: 26px;
    color: #353535;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: left;
}

.search .productCardGrid {
    margin-bottom: 0;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.table {
    background: #fff;
}

.table .thead-dark th {
    color: #fff;
    background-color: #2b2b2b;
    border-color: transparent;
    font-size: 15px;
    font-weight: 500;
}

.PaymentModal .modal-dialog.modal-lg {
    max-width: 400px !important;
}

.PaymentModal .helpingText {
    font-size: 18px;
    padding: 20px 0;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
}

.homeSkeletonBody,
.searchSkeletonBody {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 30px;
    margin-top: 30px;
}

.homeSkeletonBody .hsbGrid,
.searchSkeletonBody .ssbGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.searchSkeletonBody {
    margin: 45px 0;
}

.loginSkeletonBody {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0;
}

.loginSkeletonBody .lsbLeft {
    margin-inline-end: 15px;
    max-width: 450px;
    width: 100%;
    align-self: stretch;
    background: #f7f7f7;
}

.loginSkeletonBody .lsbRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;
    align-self: stretch;
    background: #f7f7f7;
}

.loginSkeletonBody .lsbLeft .skeleton.title,
.loginSkeletonBody .lsbRight .skeleton.title,
.loginSkeletonBody .lsbRight .skeleton.avatar {
    margin: 0 auto;
}

.loginSkeletonBody .lsbLeft .lsbBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.regSkeletonBody {
    width: 600px;
    margin: 45px auto;
}

.regSkeletonBody .lsbBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.regSkeletonBody .skeleton.title {
    margin: 0 auto;
}

.checkoutSkeletonBody {
    margin: 45px 0;
}

.checkoutSkeletonBody .csbItem {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;
    margin-top: 15px;
}

/* input:not(:focus)::-webkit-datetime-edit {
  color: transparent;
} */

/* input::placeholder {
  color: #000;
} */
/* 
input::-webkit-datetime-edit {
  color: #000;
} */

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

button .MuiButton-label {
    /* font-family: 'Kumbh Sans'; */
    font-family: var(--fontFamily);
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
    text-transform: capitalize;
}

.naBreadcrumbs.MuiTypography-root {
    margin: 15px 0;
}

.naBreadcrumbs a {
    color: #797979;
}

.slick-slider {
    cursor: grab;
}

.slick-slider:active {
    cursor: grabbing;
}

.pagination-wrapper {
    margin-top: 30px;
}

.pagination-wrapper h6 {
    margin: 0;
}

.actionWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}

.actionWrapper > button:first-child {
    margin-inline-end: 30px;
}

.cursorDecoy {
    cursor: pointer;
}

.verifyPhone .phVer {
    display: block;
    margin: 10px 0;
}

.verifyPhone .phVerInp {
    margin-top: 25px;
}

.customModal {
    z-index: 1500 !important;
}

/* .resendSms {
    margin-inline-end: 35px;
} */

.np-pro-box {
    padding: 25px 15px;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
}

.np-pro-box img {
    width: 180px;
}

.np-pro-box h5 {
    margin-top: 20px;
    font-weight: 500;
}

.np-pro-box h6 {
    line-height: 24px;
    font-size: 15px;
    color: #6d6d6d;
}

.MuiSlider-root {
    color: rgb(66, 120, 185) !important;
}

.filterClose {
    float: right;
    display: block;
    z-index: 100;
}

/* .registration .googleSignUp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
} */

.registration .googleSignUp .S9gUrf-YoZ4jf iframe {
    margin-left: auto !important;
    margin-right: auto !important;
}

.registration .googleSignUp .fbSignup .MuiButton-label,
.loginBox .socialButtons .fbLogin .MuiButton-label {
    font-size: 14px;
    text-transform: initial;
    font-weight: 500;
}

.registration .googleSignUp .fbSignup .MuiButton-label span,
.loginBox .socialButtons .fbLogin .MuiButton-label span {
    display: inline-block;
    margin-inline-end: auto;
}

.registration .googleSignUp .fbSignup object,
.loginBox .socialButtons .fbLogin object {
    margin-inline-end: auto;
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.loginBox .socialButtons {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center !important;
}

/* .loginBox .socialButtons .fbLogin {
    width: 100%;
} */

.skeletonWrapper {
    box-shadow: none !important;
    border-radius: 0 !important;
}

body.arabic .MuiFormLabel-root.MuiInputLabel-root {
    left: inherit !important;
    right: 1.75rem !important;
    top: -2px !important;
    transform-origin: right !important;
    /* background: #fff; */
}

body.arabic .MuiInputBase-root legend {
    margin-left: auto !important;
}

body.arabic .MuiFormHelperText-root.Mui-error {
    text-align: right;
}

body.arabic .customSelect .MuiSelect-root.MuiSelect-select {
    text-align: right;
    padding-left: 32px;
    padding-right: 18px;
}

body.arabic .customSelect .MuiSvgIcon-root.MuiSelect-icon {
    left: 7px;
    right: initial;
}

body.arabic .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
    padding-left: 14px;
}

.languageMenu {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: 500;
}

.languageMenu img {
    margin-right: 8px;
    width: 24px;
    object-fit: contain;
}

body.arabic .languageMenu img {
    margin-left: 8px;
    margin-right: 0;
}

.naamTable .table thead th {
    border-bottom: none;
    font-size: 14px;
}

.naamTable .table tbody td {
    font-size: 15px;
    vertical-align: middle;
}

.naamTable .table tbody td .wbDebit {
    color: rgb(200, 70, 44);
}

.naamTable .table tbody td .wbCredit {
    color: rgb(24, 163, 22);
}

.dashboard .searchResults.myInvoicesCnt.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 15px;
}

.dashboard .searchResults.myInvoicesCnt.Grid .invoiceCard {
    width: 100%;
    margin: 0;
}

.wpwl-apple-pay-button {
    -webkit-appearance: -apple-pay-button !important;
}

.dashboard .avatarUploader {
    margin-left: auto;
    margin-right: auto;
}

.primGoldBtn button {
    color: var(--secColor) !important;

    background-color: var(--primColor) !important;
}
.primGoldBtn button:hover {
    color: var(--secColor) !important;

    background-color: var(--primColor) !important;
}
.primBlackBtn button {
    color: white !important;

    background-color: var(--secColor) !important;
}
.primBlackBtn button:hover {
    color: white !important;

    background-color: var(--secColor) !important;
}

.MuiInputLabel-outlined {
    font-size: 13px !important;
    color: var(--accentColorPrim) !important;
}
.productBidInput .MuiInputLabel-outlined {
    font-size: 15px !important;
    color: #bab1b1 !important;
}
.productBidInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-size: 13px !important;
    color: var(--secColor) !important;
}

.MuiSelect-select.MuiSelect-select {
    background: #f9fbfd;
    font-size: 12px;
    padding: 17px 32px 17px 24px;
    color: var(--secColor);
    border-radius: 12px;
}

.productBidInput .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: 1px solid #e1e3e5 !important;
    /* border-color: transparent !important; */
}
.MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: 1px solid #d9e4f2;
    border-color: transparent !important;
}

.pointsPaymentCnt {
    padding-left: 0 !important;
}
.pointsPaymentCnt h6 {
    font-size: 14px;
    font-weight: 400;
}
.pointsPaymentCnt .MuiIconButton-label {
    color: var(--accentColorPrim);
}

.addPointsForm .actionWrapper > button.MuiButtonBase-root {
    color: var(--secColor);
}
.addPointsForm .actionWrapper .primButton button {
    color: #fff;
    border-radius: 20px;
    background: var(--secColor);
}

.MuiTypography-root {
    font-family: var(--fontFamilySec) !important;
}
.productBidInput .customInput .MuiInputBase-input {
    background: white;
    border-radius: 4px;
}
.customInput .MuiInputBase-input {
    background: #f5f8fc;
    border-radius: 4px;
}

@media (min-width: 1440px) {
    .customContainer {
        padding-inline-start: 30px !important;
        padding-inline-end: 30px !important;
    }

    .home .homeLt,
    .search .searchLt,
    .dashboard .dashboardLt {
        width: 20%;
    }

    .home .homeRt,
    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: 80%;
    }

    .home .homeLt,
    .search .searchLt {
        position: sticky;
        top: 10px;
    }

    header .scrollTop {
        display: inline-flex !important;
    }
}

.verificationModeModal .primButton button {
    border-radius: 15px;
}
.verificationModeModal .secButton button:hover {
    background: var(--secColor);
}

body.arabic .verificationModeModal .secButton button {
    margin-left: 16px;
}

.ciIcon img {
    width: 20px;
    height: 20px;
}

@media (max-width: 1366px) {
    .search .productCardGrid,
    .dashboard .productCardGrid {
        margin-inline-end: 10px;
    }
}

@media (max-width: 1300px) {
    .search .productCardGrid,
    .dashboard .productCardGrid {
        margin-inline-end: 5px;
    }
}

@media (max-width: 1200px) {
    .buyNowCart .cartItem {
        flex-wrap: wrap;
    }

    .buyNowCart .cartProducts .cartItem .itemInfo {
        width: 100%;
    }
}

@media (min-width: 1025px) {
    header .scrollTop {
        display: inline-flex !important;
    }
}

@media (max-width: 1024px) {
    .home .homeRt,
    .search .searchRt,
    .dashboard .dashboardRt {
        max-width: none;
    }

    .customContainer {
        padding-inline-start: 40px !important;
        padding-inline-end: 40px !important;
        width: 100% !important;
    }

    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        margin-inline-end: 10px;
    }

    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 20px !important;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .home .homeCnt,
    .search .searchCnt {
        flex-wrap: wrap;
    }

    .home .homeLt,
    .search .searchLt {
        width: 100%;
        max-width: none;
        width: 100%;
        max-width: none;
        margin-inline-end: 0;
    }

    .deskFilter {
        display: none;
    }

    .home .hmSearch {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .home .hmSearch .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    /* .responsiveFilterDrawer .MuiCollapse-container {
        width: auto;
        min-width: 100%;
        left: 0;
        position: absolute;
    } */

    /* .toggleRespDrawer.MuiButtonBase-root {
        display: inline-flex !important;
        border: none;
        color: var(--primColor);
    } */

    .toggleRespDrawer.MuiButtonBase-root .material-icons {
        padding-inline-end: 10px;
    }

    .filterAcc {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin-inline-end: 10px;
        width: 100%;
        position: static;
        margin-top: 0;
    }

    .filterPanel .filterAcc .MuiCollapse-wrapper {
        width: 100%;
    }

    .filterPanel .filterAcc .MuiAccordionSummary-root {
        /* border: 1px solid #d8d8d8; */
        height: 40px;
        min-height: initial;
        border-radius: 50px;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        min-height: 300px;
        max-height: 90vh;
        height: 100%;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        /* background: rgb(229 237 248); */
        border-color: var(--primColor);
    }

    .dashboard .dashboardLt {
        display: none;
    }

    .dashboard .dashboardRt {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-inline-start: 20px;
    }
}

@media (max-width: 800px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 32%;
    }
}

@media (max-width: 600px) {
    .homeSpecialItems {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .homeSpecialItems .specialEvent {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeSpecialItems .specialEvent .specialEventLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeSpecialItems .specialEvent .seActBtn {
        width: 100%;
        max-width: none;
    }

    .homeSpecialItems .specialEvent .seImg,
    .homeSpecialItems .specialEvent .seContent {
        margin-inline-end: 0;
    }

    .homeSpecialItems .specialEvent .seImg {
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .home .hmSearch .searchInput {
        max-width: 100%;
        width: 100%;
        margin-inline-end: 0px;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .home .sectionTitle {
        margin-bottom: 20px;
    }

    .filterPanel .filterAcc .MuiAccordionSummary-root {
        min-height: 30px;
        height: 40px;
        margin-bottom: 0px;
    }

    .filterPanel .filterAcc .MuiAccordion-root {
        padding: 0px 20px;
    }

    .filterPanel .accTitle {
        font-size: 13px !important;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        min-height: 65vh;
    }

    .productNav {
        display: none;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }
}

@media (max-width: 500px) {
    button .MuiButton-label {
        font-size: 12px;
    }
    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 20px !important;
    }

    .specialEvent .seImg {
        max-width: none !important;
    }

    .specialEvent .seImg img {
        height: 150px !important;
    }

    .pagination-wrapper {
        margin: 20px auto;
    }

    .pagination-wrapper > div {
        font-size: 12px;
    }

    .pagination-wrapper h6 {
        width: 100%;
        margin-bottom: 15px;
    }

    .pagination-wrapper .MuiPagination-root {
        margin: 0 auto;
    }
    .filterPanel .fpTitle {
        font-size: 20px !important;
        text-transform: capitalize !important;
    }
}

@media (max-width: 480px) {
    .filterAcc {
        justify-content: flex-start;
    }

    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin: 0 10px 10px 0;
    }

    /* .responsiveFilterDrawer .MuiCollapse-container {
        top: 120px;
    } */
}

@media (max-width: 400px) {
    .homeSpecialItems .specialEvent {
        width: 100%;
    }

    .specialEvent .seImg {
        max-width: none;
    }

    .home .sectionTitle h2,
    .search .sectionTitle h2,
    .dashTitle {
        font-size: 18px !important;
    }

    .home .sectionTitle > object {
        width: 25px;
    }
}

@media (max-width: 380px) {
    .filterPanel .filterAcc .MuiPaper-root.MuiAccordion-root {
        margin: 0 0px 00px 0;
        /* width: 45%; */
    }
}

@media print {
    .MuiDialog-paperFullScreen header,
    .noPrint {
        display: none !important;
    }

    .productInvoice {
        height: 100% !important;
        width: 100vw !important;
        margin-top: 0 !important;
        min-height: 100vh;
    }

    .productInvoice .invoiceView {
        height: auto !important;
    }
}
