.checkout {
    background: var(--backgroundColor);
    padding-top: 30px;
    padding-bottom: 30px;
}

.checkout .checkoutHead {
    font-size: 18px;
    text-transform: uppercase;
    color: var(--secColor);
    font-weight: 600;
    margin: 0;
    text-align: left;
    margin-bottom: 20px;
}

.checkout .checkoutHead ~ span {
    padding-inline-start: 10px;
    display: inline-block;
}

.checkout .checkoutSecAction {
    background: #fff;
    padding: 15px;
    margin-top: 20px;
}

.checkout .checkoutSecAction label {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    padding-inline-end: 10px;
}

.checkout .checkoutSecAction h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    display: inline;
}

.checkout .selectDate {
    width: 200px;
    height: 100px;
}

.checkout .selectDate label {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 1px dashed #777;
}

.checkout .selectDate input:checked ~ label {
    background: var(--primColor);
    color: #fff;
    border: 1px dashed var(--primColor);
}

.checkout .selectDate label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-inline-end: 10px;
    -webkit-transition: 250ms all ease-in-out;
    -o-transition: 250ms all ease-in-out;
    transition: 250ms all ease-in-out;
}

.checkout .selectDate label p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
}

.checkout .selectDate label span {
    margin: 0;
    font-size: 14px;
}

.checkout .selectDate label .MuiListItem-button {
    height: 100%;
    flex-wrap: wrap;
}

.checkout .schedule,
.checkout .payment,
.checkout .review,
.checkout .billingInfo {
    margin-bottom: 15px;
    background: #fff;
    padding: 20px 15px;
    border-radius: 10px;
}

.checkout .schedule .scheduleError {
    font-size: 13px;
    color: #f44336;
    text-align: left;
    margin-top: 2px;
}

.checkout .payment .cardDetails {
    margin-top: 15px;
}

.checkout .payment .cardDetails h4 {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 15px 0;
}

.checkout .selectTime .customSelect,
.checkout .paymentMethod .customSelect {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: #fff;
    margin-top: 20px;
    min-width: 200px;
}

.checkout .selectTime > label,
.checkout .paymentMethod > label {
    margin-inline-end: 20px;
}

.checkout .payment .paymentMethod {
    background: #fff;
    margin: 20px 0 10px 0;
    padding: 20px 0;
}

.checkout .schedule .selectTime {
    background: #fff;
    margin: 10px 0;
    padding: 20px 0 0 0;
}

.checkout .payment .paymentMethod .customSelect,
.checkout .schedule .selectTime .customSelect {
    margin: 0;
}

.checkout .selectCard label {
    margin: 0;
}

.checkout .selectCard p {
    margin: 0;
    text-align: left;
}

.checkout .selectCard table {
    width: 100%;
}

.checkout .selectCard table tr {
    border-bottom: 5px solid whitesmoke;
    border: 1px dashed #b6b6b6;
}

.checkout .selectCard table td {
    padding: 10px;
}

.checkout .checkoutLt {
    width: 68%;
}

.checkout .checkoutLt .isMultiCardSelection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.checkout .checkoutLt .subHead {
    margin: 0;
    text-transform: initial;
    margin-inline-end: 15px;
    font-size: 16px;
}

.checkout .checkoutLt .cartItem {
    margin-bottom: 0;
}

.checkout .checkoutLt .noMarginBottom .customInput {
    margin-bottom: 0;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-label {
    width: max-content;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-root {
    margin: 0;
    margin-inline-end: 15px;
}

.checkout .checkoutLt .isMultiCardSelection .MuiButtonBase-root.MuiListItem-root {
    width: max-content;
    margin-inline-end: 20px;
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormGroup-root {
    flex-wrap: nowrap;
}

.billingAddress {
    text-align: left;
}

.billingAddress h4 {
    font-size: 16px;
    font-weight: 600;
}

.billingAddress p {
    margin-bottom: 0;
    margin-top: 5px;
}

.billingInfo button {
    color: var(--primColor);
    border-color: var(--primColor);
}

.checkout .checkoutLt .isMultiCardSelection .MuiFormControl-root,
.checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-root {
    width: 100%;
}

.checkout .checkoutRt {
    width: 30%;
    text-align: left;
}

.checkout .checkoutRt .orderSummary {
    background: #fff;
    padding: 15px;
    position: sticky;
    top: 15px;
    border-radius: 15px;
}

.checkout .checkoutRt .primButton {
    margin-bottom: 20px;
}

.checkout .checkoutRt h3 {
    font-size: 15px;
    color: var(--secColor);
    font-weight: 700;
    text-transform: uppercase;
}

.checkout .checkoutRt p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
}

.checkout .checkoutRt p,
.checkout .checkoutRt p label {
    font-size: 14px;
    color: var(--secColor);
    font-weight: 500;
    margin-bottom: 5px;
}

.checkout .checkoutRt h4 {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-item-align: center;
    align-self: center;
    margin-bottom: 0;
}

.checkout .checkoutRt h4 label {
    margin-bottom: 0;
}

.existingAppointmentBanner {
    background: #ffe3de;
    padding: 30px 15px;
    position: relative;
    overflow: hidden;
}

.existingAppointmentBanner > div {
    z-index: 1;
}

.existingAppointmentBanner .material-icons {
    position: absolute;
    font-size: 80px;
    color: #ffd5ce;
    top: -10px;
    left: -30px;
}

.existingAppointmentBanner h2 {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: left;
}

.existingAppointmentBanner h4 {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 0;
}

.existingAppointmentBanner .primButton {
    margin-bottom: 0;
}

@media (max-width: 1024px) {
    .checkoutCnt {
        flex-wrap: wrap;
        /* flex-direction: column-reverse; */
    }
    .checkout .checkoutLt,
    .checkout .checkoutRt {
        width: 100%;
    }
    .checkout .checkoutRt .orderSummary {
        position: static;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .checkout .checkoutRt .orderSummary .primButton button {
        width: 200px;
        margin-top: 20px;
        float: right;
    }
    .checkoutSecAction {
        display: none !important;
    }
}

@media (max-width: 845px) {
    .existingAppointmentBanner .primButton {
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .existingAppointmentBanner {
        padding: 20px 15px;
    }
    .existingAppointmentBanner h2 {
        font-size: 1.2rem;
    }
    .existingAppointmentBanner h4 {
        font-size: 0.9rem;
    }
    .chCnt {
        flex-wrap: wrap;
    }
    .checkout .selectDate {
        margin-bottom: 15px;
        margin-inline-end: 15px;
        width: 45%;
        height: 70px;
    }
    .checkout .selectDate label {
        margin-inline-end: 0;
    }
    .checkout .selectDate label p {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .checkout .checkoutLt .noMarginBottom .customInput {
        margin-bottom: 30px;
    }
    .checkout .payment {
        padding-bottom: 0;
    }
    .checkout .schedule .selectTime {
        padding-top: 0;
    }
}

@media (max-width: 500px) {
    .checkout {
        padding-top: 10px;
    }
    .checkout .selectDate {
        width: 100%;
        height: max-content;
    }
    .checkout .selectDate label p {
        width: max-content;
    }

    .checkout .selectDate label span {
        margin-inline-start: auto;
    }

    .checkout .checkoutLt .noMarginBottom .customInput {
        margin-bottom: 25px;
    }

    .checkout .checkoutLt .subHead {
        font-size: 14px;
    }
    .checkout .checkoutHead {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .checkout .payment .paymentMethod {
        margin: 10px 0 10px 0;
        padding: 10px 0;
    }
    .checkout .checkoutLt .isMultiCardSelection .MuiFormControlLabel-label {
        font-size: 14px;
    }
    .checkout .checkoutRt .orderSummary .primButton button {
        width: 100%;
    }
}

@media (max-width: 440px) {
    .checkout .payment .paymentMethod,
    .checkout .schedule .selectTime {
        flex-wrap: wrap;
    }

    .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .checkout .selectTime .customSelect,
    .checkout .paymentMethod .customSelect {
        width: 100%;
    }

    .checkout .schedule .selectTime .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

@media (max-width: 400px) {
    .checkout .checkoutLt .isMultiCardSelection {
        flex-wrap: wrap;
    }

    .checkout .checkoutLt .isMultiCardSelection .checkout .checkoutLt .subHead {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
