.home .homeBanner {
    /* display: grid;
    grid-template-columns: 50% 50%; */
    background: #262626;
    display: flex;
    /* grid-template-columns: 50% 50%; */

    max-width: 100%;
    height: 640px;
}

.home .homeBanner .swiper {
    flex-grow: 1;
    width: 100%;
}

.home .homeBanner .swiper-pagination-bullet {
    border: 1px solid #333;
    opacity: 1;
    background: #fff;
}

.home .homeBanner .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: 1px solid #333;
    opacity: 1;
    background: var(--primColor);
}

.home .homeBanner .hbImages {
    flex-grow: 1;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; */
    /* gap: 2px; */
    display: flex;
    flex-direction: column;
    background: #bababa;
    width: 100%;
    height: 640px;
}

.home .homeBanner .hbImages.mobile {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space-evenly;
    gap: 15px;
    background: #fff;
    padding-bottom: 15px;
}

.home .homeBanner .hbImages.mobile .hiItemMob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home .homeBanner .hbImages.mobile .hiItemMob span {
    font-size: 15px;
    color: #333;
    font-weight: 400;
    display: block;
    margin-top: 10px;
    font-family: var(--fontFamilySec);
}

.home .homeBanner .hbImages.mobile img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.home .homeBanner .hbImages .hbiOdd,
.home .homeBanner .hbImages .hbiEven {
    display: flex;
    /* flex-direction: column; */
    gap: 0px;
    height: 50%;
    /* height: 640px; */
}

.home .homeBanner .hbImages .hbiOdd img,
.home .homeBanner .hbImages .hbiEven img {
    height: 100%;
}

.home .homeBanner .hbImages .hbiOdd img,
.home .homeBanner .hbImages .hbiEven img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}

.home .homeBanner .hbImages .hbiOdd .hiItem {
    height: 100%;
}
.home .homeBanner .hbImages .hbiOdd .hbiItemWrapper .hiItem {
    height: 50%;
}

.home .homeBanner .hbImages .hbiEven .hiItem {
    height: 100%;
}

.hbiItemWrapper {
    display: flex;
    width: 43%;
    flex-direction: column;
    height: 100%;
}

.hbiOdd > div:nth-child(1) {
    width: 26%;
}

.hbiOdd > div:nth-child(2) {
    width: 32%;
}

.hbiEven > div:nth-child(1) {
    width: 20%;
}

.hbiEven > div:nth-child(2) {
    width: 20%;
}

div.hiItem:nth-child(3) {
    width: 30%;
}

div.hiItem:nth-child(4) {
    width: 30%;
}

.home .homeBanner .hbImages .hiItem {
    position: relative;
    cursor: pointer;
}

.home .homeBanner .hbImages .hiItem span {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 300ms all ease;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    transform: translateY(-100%);
    padding: 24px 20px;
    font-family: var(--fontFamilySec);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    opacity: 1;
    background: linear-gradient(45deg, rgba(63, 118, 184, 0.3) 0%, rgba(63, 118, 184, 0.2) 100%);
    visibility: visible;
}
.home .homeBanner .hbImages .hiItem span div {
    display: flex;
    align-items: center;
    gap: 8px;
}
.home .homeBanner .hbImages .hiItem span div img {
    width: 24px;
    height: 24px;
}

.home .homeBanner .hbImages .hiItem:hover span {
    background: linear-gradient(45deg, rgba(4, 19, 38, 1) 0%, rgba(17, 56, 105, 0.5) 100%);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    visibility: visible;
    opacity: 1;
}

.home .homeBanner .hbContent {
    padding: 60px 45px 30px;
    text-align: left;
    /* width: max-content; */
}

body.arabic .home .homeBanner .hbContent {
    text-align: right;
}

.home .homeBanner .hbContent h1 {
    color: #fff;
    font-family: 'Playball';
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 15px;
}

.home .homeBanner .hbContent p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.home .homeBanner .hbContent .hbActions {
    margin-top: 30px;
    display: flex;
    gap: 15px;
}

.home .homeBanner .hbContent .hbActions > button {
    color: #fff;
}

.home .howItWorksCnt {
    margin: 96px 0px 72px 0px;
}

.home .howItWorksCnt .worksImgTitleCnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 19px;
    gap: 24px;
    border: 1px solid var(--accentColorPrim);
    border-radius: 15px;
}

.worksImgTitleCnt > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px;
}

.home .howItWorksCnt h2 {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
}

.home .howItWorksCnt .howItWorksInner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}

.home .howItWorksCnt .howItWorksInner img {
    width: 36px;
    height: 36px;
    object-fit: contain;
}
.home .howItWorksCnt .howItWorksInner h3 {
    /*! color: var(--secColor); */
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    /* margin: 15px 0 10px; */
    margin-bottom: 0px !important;
    font-family: var(--fontFamilySec);
}
.home .howItWorksCnt .howItWorksInner p {
    color: #1c1c1c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}

.home .trendingItems .slick-slide,
.home .activeAuctions .slick-slide,
.home .liveAuctions .slick-slide {
    margin: 0 2px;
}

.home .homeTitle {
    margin-bottom: 30px;
}

.home .homeTitle h2 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    width: fit-content;
    text-align: left;
    white-space: nowrap;
}

.home .homeTitle a {
    color: #4f7eb7;
    font-size: 14px;
    font-weight: 600;
    text-transform: initial;
}

.home .trendingItems .productCardGrid {
    margin: 0;
    width: 100%;
}

.home .activeAuctions {
    margin-top: 48px;
    padding: 24px 0px;
    position: relative;
}
.home .activeAuctions .secButton button:hover {
    background: var(--secColor);
}

.home .normalActiveAuctions::before {
    content: '';
    background: #d9e4f2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100%; /* Full height of the parent element */
    z-index: -1; /* Place the background behind the content */
    left: 50%;
    transform: translateX(-50vw); /* Ensures background starts at the viewport edge */
}

.home .normalActiveAuctions .secButton button {
    background-color: white;
    color: var(--secColor);
    border: 0;
}

.reviewCnt {
    margin: 45px 0;
}

.reviewCnt h2 {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
}

.reviewCnt .reviewCard {
    border-radius: 15px;
    background: #fff;
    /* box-shadow: -2px 5px 8px 2px rgba(32, 0, 53, 0.1); */
    padding: 21px;
    text-align: left;

    border: 1px solid var(--primColor);
    /* min-height: 180px; */
}

.reviewCnt .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border: 1px solid var(--primColor);
    box-shadow: none;
    background: transparent;
    opacity: 1;
    margin: 0 6px !important;
}
.reviewCnt .swiper-pagination-bullet-active {
    background: var(--primColor);
}

.reviewCnt .reviewCard hr {
    background: var(--primColor);
    opacity: 0.5;
}

.reviewCnt .reviewCard p {
    color: #000;
    font-size: 12px;
    word-break: break-word;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
}

.reviewCnt .reviewCard .readMore {
    font-size: 14px;
}

.reviewCnt .reviewCard .reviewUser {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.reviewCnt .reviewCard .reviewUser .userInitials {
    width: 30px;
    height: 30px;
    font-size: 12px;
    background: #3d3d3d;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewCnt .reviewCard h5 {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--fontFamilySec);

    margin-bottom: 0px;
}

.reviewCnt .reviewCard img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.reviewCnt .reviewCard .reviewRating > span {
    /* color: #72cf72; */
    color: var(--primColor);
    font-size: 18px;
}

.home .reviewCnt .reviewSwiper {
}

.home .reviewCnt .reviewSwiper .swiper-pagination {
    position: static;
    margin-top: 36px;
    display: flex;
    justify-content: center;
}

.home .trendingItems .loadingCnt,
.home .activeAuctions .loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 15px;
}

.home .trendingItems .productCardGrid {
    padding-bottom: 15px;
}
.home .trendingItems .productCardGrid .gridBidBox .customInput {
    position: relative;
}
.home .trendingItems .productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -22px;
}

.home .trendingItems .homeProdMainCnt {
    /* display: flex;
    gap: 16px;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    flex-wrap: wrap;
    gap: 16px;
}

.home .homeTitle hr {
    width: 60%;
    background: #98b6dc;
}
.home .activeActiveAuctions hr {
    width: 45%;
    background: #98b6dc;
}

.activeActiveAuctionBtns .secButton:not(:last-child) button {
    background: #dee2e7;
    border: 0;
    color: var(--secColor);
}
.activeActiveAuctionBtns .secButton:not(:last-child) button:hover {
    background: #dee2e7;
}

.normalActiveAuctions .secButton button:hover {
    background: white !important;
}

.homeHeadCnt {
    font-family: var(--fontFamilySec);
    display: flex;
    flex-direction: column;
    font-weight: 40px;
    font-weight: 400;
    align-items: flex-start;
    line-height: 52px;
    margin-left: 24px;
}
.homeHeadCnt span {
    text-align: left;
}
.homeBannerTitleCnt {
    position: relative;
    height: 48px;
    margin: 0px 0px 32px 0px;
    z-index: 1;
}
.homeBannerTitleCnt h5 {
    background: #fff;
    /* border-radius: 100px; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 30px;
    position: absolute;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 7px;
    text-wrap: nowrap;
}

body.arabic .homeBannerTitleCnt h5,
body.arabic .howItWorksTitleCnt h5,
body.arabic .home .homeTitle h2 {
    letter-spacing: 0px !important;
}

.howItWorksTitleCnt h5,
.home .homeTitle h2 {
    font-size: 32px;
    letter-spacing: 4px;
    font-family: var(--fontFamilySec);
    font-weight: 600;
}
.homeBannerTitleCnt.howItWorksTitleCnt hr {
    background: #98b6dc !important;
}

.homeBannerTitleCnt hr {
    top: 50%;
    background-color: var(--primColor);
    position: absolute;
    width: 100%;
    margin: 0;
    z-index: -1;
    transform: translateY(-50%);
}

.homeTopBannerTitleCnt hr {
    height: 1.5px;
}

.home .auctionProdMainCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    flex-wrap: wrap;
    gap: 16px;
}
.home .auctionProdMainCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    flex-wrap: wrap;
    gap: 16px;
}

.userRatingCnt {
    display: flex;
    justify-content: space-between;
}

/* Responsive */

@media (max-width: 1260px) {
    .home .homeBanner .hbContent {
        padding: 30px 30px 35px;
    }
    .home .homeBanner .hbContent p {
        font-size: 14px;
    }
    .home .homeBanner .hbContent h1 {
        font-size: 24px;
    }
    /* .home .homeBanner .hbImages .hbiOdd img,
    .home .homeBanner .hbImages .hbiEven img {
        width: 160px;
    } */
}

@media (max-width: 1150px) {
    .home .homeTitle h2 {
        white-space: wrap;
    }
}

@media (max-width: 992px) {
    /* .home .homeBanner .hbImages .hbiOdd img,
    .home .homeBanner .hbImages .hbiEven img {
        width: 120px;
    } */
    .home .homeBanner .hbContent {
        padding: 25px 25px 30px;
    }
}

@media (max-width: 768px) {
    .home .howItWorksCnt .howItWorksInner h3 {
        font-size: 16px;
    }
    .home .howItWorksCnt .howItWorksInner p {
        font-size: 14px;
    }
    .home .howItWorksCnt .howItWorksInner {
        gap: 20px;
    }
}

@media (max-width: 750px) {
    .home .homeBanner {
        grid-template-columns: 100%;
        gap: 0;
        flex-wrap: wrap;
        background: #fff;
        margin-top: 15px;
    }
    .home .homeBanner .hbContent {
        background: #262626;
    }
    .home .homeBanner .hbImages .hbiOdd img,
    .home .homeBanner .hbImages .hbiEven img {
        width: 100%;
        height: auto;
        min-height: -webkit-fill-available;
        max-height: initial;
    }
}

@media (max-width: 600px) {
    .home .howItWorksCnt .howItWorksInner {
        grid-template-columns: auto auto;
        gap: 15px;
    }
}

@media (max-width: 500px) {
    .home .howItWorksCnt {
        margin: 24px 0px 64px 0px;
    }
    .home .trendingItems .homeProdMainCnt {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    .home .homeTitle h2,
    .home .homeBanner .hbContent h1 {
        font-size: 18px;
    }
    .home .homeBanner .hbContent p {
        font-size: 13px;
    }
    .home .homeBanner .hbContent {
        padding: 20px;
    }
    .home .homeBanner .hbContent .hbActions {
        margin-top: 0;
    }

    .home .homeBanner {
        margin-top: 8px;
    }

    .homeBannerTitleCnt h5 {
        padding: 0 0px;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 4px;
        text-wrap: wrap;
        width: 95%;
        margin: 8px 0px 0px 0px;
        position: relative;
    }

    body.arabic .homeBannerTitleCnt h5 {
        letter-spacing: 0px !important;
    }

    .homeBannerTitleCnt hr {
        display: none;
    }
    .homeHeadCnt {
        font-family: var(--fontFamilySec);
        display: flex;
        flex-direction: column;
        font-weight: 40px;
        font-weight: 400;
        align-items: center;
        line-height: 30px;
        width: 98%;
        margin-left: 0 !important;
    }
    .homeHeadCnt span {
        text-align: center;
        font-size: 22px;
    }
    .homeBannerTitleCnt {
        height: 100%;
        margin: 0px 0px 0px 0px !important;
        line-height: normal;
    }

    .search .searchBannerTitleCnt {
        margin-bottom: 40px !important;
    }

    .howItWorksTitleCnt h5,
    .home .homeTitle h2 {
        font-size: 24px;
        letter-spacing: 4px;
    }
    .home .howItWorksCnt .howItWorksInner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    home .howItWorksCnt .worksImgTitleCnt {
        width: 75%;
    }
    .home .homeTitle hr {
        display: none;
    }
    .home .homeTitle h2,
    .home .homeBanner .hbContent h1 {
        font-size: 18px;
        font-size: 20px;
    }
    .home .trendingItems .productCardGrid {
        width: 100%;
    }

    .home .howItWorksCnt .worksImgTitleCnt {
        width: 100%;
    }

    .home .activeActiveAuctions .homeTitle {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .reviewCnt .homeBannerTitleCnt.howItWorksTitleCnt h5 {
        text-wrap: wrap;
        width: 90%;
    }
}

@media (max-width: 480px) {
    .home .homeBanner .hbContent {
        text-align: center;
    }
}

@media (max-width: 375px) {
    .home .howItWorksCnt .howItWorksInner {
        grid-template-columns: auto;
        padding: 0 25px;
    }
    .home .homeBanner .hbImages.mobile img {
        width: 60px;
        height: 60px;
    }
    .home .homeBanner .hbImages.mobile .hiItemMob span {
        font-size: 14px;
    }
}
