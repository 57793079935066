.pvContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
}

.pvContainer .pvLt,
.pvContainer .pvRt {
    padding: 30px 0;
}

.pvContainer .pvLt {
    position: relative;
    height: max-content;
    padding: 24px 32px 0px 32px;
    border-radius: 15px;
    background: #f7fafc;
}

.pvContainer .pvRt {
    padding-inline-start: 30px;
}

.pvContainer .pvRt .pvTitle {
    font-size: 20px;
    color: #222;
    /* line-height: 32px; */
    font-weight: 400;
    margin: 0 0 0px 0;
}

.pvContainer .pvRt .pvTitleHeading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0;
}
.pvContainer .pvRt .pvPriceBtn {
    margin-top: 26px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}
.pvContainer .pvRt .pvPriceBtn .pvPriceCnt {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox .biddingCnt,
.pvContainer .pvRt .pvPriceBtn .pvBidBox .buyingCnt {
    display: grid !important;
    grid-template-columns: 1fr 100px;
    gap: 16px;
    justify-content: flex-start !important;
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox .customInput {
    margin-bottom: 0;
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox .customInput .MuiOutlinedInput-notchedOutline {
    border: 0.5px solid #cdd1d4;
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox .customInput .MuiOutlinedInput-root {
    /* background: #f9fbfd; */
    border-radius: 15px;
    /* border: 1px solid var(--secColor); */
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox .customInput .MuiFormLabel-root {
    font-size: 16px;
    margin-top: 5px !important;
}

.pvContainer .pvRt .pvPriceBtn .pvBidBox .customInput .MuiFormHelperText-contained {
    position: absolute;
    bottom: -24px;
    font-size: 12px;
}
.pvContainer .pvRt .pvPriceBtn .pvBidBox .customInput .MuiInputBase-input {
    padding: 16px 32px;
}

.pvContainer .pvRt .pvWpriceCnt {
    background: #ecf1f8;
    padding: 8px 12px;
    border-radius: 2px;
}
.pvContainer .pvRt .pvWpriceCnt span {
    font-size: 20px;
}

.pvContainer .pvRt .pvPriceBtn .pvWpriceCnt span:nth-child(1) {
    font-family: var(--fontFamilySec);
    font-weight: 400 !important;
}
.pvContainer .pvRt .pvPriceBtn .pvWpriceCnt span:nth-child(2) {
    font-family: var(--fontFamilySec);
    font-weight: 600 !important;
    margin-left: 12px;
}

.pvContainer .pvRt .pvPriceBtn .primButton {
    margin-top: 0 !important;
}
.pvContainer .pvRt .pvPriceBtn .primButton button {
    border-radius: 20px;
}

.pvContainer .pvPrimInfo {
    /* display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px; */
    padding: 16px 12px;
    border-radius: 10px;
    /* box-shadow: #0003 0 3px 1px -2px, #00000024 0 2px 2px, #0000001f 0 1px 5px; */
    background: #f9fbfd;
    margin: 32px 0;
    align-items: center;
}
.pvContainer .pvPrimInfo .pvAucDetails {
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
}
.pvContainer .pvPrimInfo .pvAucDetails h4 {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    font-size: 16px;
}
.pvContainer .pvPrimInfo .pvAucDetails h4 p {
    margin-bottom: 0px;
    font-weight: 600;
}

.pvContainer .pvPrimInfo .pvBidBox form {
    margin-bottom: 10px;
}

body.arabic .pvContainer .pvPrimInfo .pvAucDetails h4 > span > span {
    margin-inline-start: 5px;
    margin-inline-end: 0;
}

.pvContainer .pvPrimInfo .biddingCnt .customInput,
.pvContainer .pvPrimInfo .buyingCnt .customInput {
    margin: 0;
}

.pvContainer .pvPrimInfo .buyingCnt .customInput {
    /* margin-right: 12px; */
}

.pvContainer .pvPrimInfo .biddingCnt .customInput label,
.pvContainer .pvPrimInfo .buyingCnt .customInput label {
    font-size: 14px;
}

.pvContainer .pvPrimInfo .biddingCnt .customInput .MuiFormHelperText-root,
.pvContainer .pvPrimInfo .buyingCnt .customInput .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
}

.pvContainer .pvPrimInfo .biddingCnt .customInput fieldset,
.pvContainer .pvPrimInfo .buyingCnt .customInput fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

body.arabic .pvContainer .pvPrimInfo .biddingCnt .customInput fieldset,
body.arabic .pvContainer .pvPrimInfo .buyingCnt .customInput fieldset {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pvContainer .pvPrimInfo .biddingCnt .primButton button,
.pvContainer .pvPrimInfo .buyingCnt .primButton button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 120px;
}

body.arabic .pvContainer .pvPrimInfo .biddingCnt .primButton button,
body.arabic .pvContainer .pvPrimInfo .buyingCnt .primButton button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.pvContainer .pvSubtitle {
    font-size: 20px;
    font-weight: 400;
    color: #222;
    /* text-transform: uppercase; */
    letter-spacing: 0.5px;
    margin: 10px 0 20px 0;
}

.pvContainer .pvDescChild .image img {
    max-width: 100%;
}

.pvContainer .qualityRating .pvRating {
    display: flex;
    gap: 5px;
    color: #72cf72;
    margin: 8px 0;
}

.pvContainer .qualityRating .pvQuality {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 15px 0;
}

.pvContainer .qualityRating .pvQuality span {
    display: inline-block;
    padding: 12px 20px;
    font-size: 15px;
    color: var(--secColor);
    font-weight: 400;
    border-radius: 10px;
    background: #daeafe;
}

.pvContainer .qualityRating .pvQuality span.bad {
    background: #ffdede;
    color: #d05858;
}

.pvContainer .pvDescChild h6 {
    font-size: 15px;
    color: #222;
    font-weight: 400;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.pvContainer .pvDescChild h6:not(:last-child) {
    margin-bottom: 10px;
}

.pvContainer .pvDescChild h6 span {
    font-weight: 600;
    /* text-transform: uppercase; */
    display: inline-block;
    margin-inline-end: 5px;
    font-size: 16px;
    color: var(--secColor);
}

.pvContainer .pvBidHistory,
.pvContainer .pvItemDetails,
.pvContainer .qualityRating {
    margin-top: 30px;
}

body.arabic .pvContainer .pvItemDetails,
body.arabic .pvContainer .pvSubtitle {
    text-align: right;
}

body.arabic .bidHistorySection,
body.arabic .pvContainer .pvBidHistory table th,
body.arabic .pvContainer .pvBidHistory table td {
    text-align: right;
}

.pvContainer .pvBidHistory table {
    background: transparent;
}

.pvContainer .pvBidHistory table th,
.pvContainer .pvBidHistory table td {
    font-size: 14px;
}

.pvContainer .pvBidHistory .pagination-wrapper {
    margin-top: 15px;
}

.pvContainer .pvLt .pvTop {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    /* position: absolute; */
    z-index: 100;
    /* width: 100%; */

    /* padding: 0 40px !important; */
    margin-bottom: 22px;
}
/* 
.pvContainer .pvLt .pvTop .pcgPoints {
    width: max-content;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: var(--secColor);
    border-radius: 8px;

    display: flex;
    align-items: center;
} */
.pvContainer .pvLt .pvTop .pvTopChildCnt,
.pvContainer .pvLt .pvTop .pcgPoints {
    display: flex;
    border-radius: 15px;
    padding: 12px 20px;
    background: var(--secColor);
    gap: 16px;
    align-items: center;
}

.pvContainer .pvLt .pvTop .pcgPoints {
    color: #fff;
}

.pvTop button,
.pvTop .favoriteCheck {
    height: fit-content;
    /* border-radius: 10px; */
    /* background: var(--secColor); */
    color: white;
}

.pvTop .MuiButton-text {
    padding: 0 !important;
}

.pvTop .favoriteCheck,
.pvTop button.share {
    width: fit-content;
    min-width: initial;
}

.pvTop .favoriteCheck label {
    color: #333;
}

.pvTop button.share .material-icons {
    font-size: 18px;
}

.pvContainer .image-gallery-left-nav,
.pvContainer .image-gallery-right-nav {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    min-width: initial;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #ffffff;
    padding: 0;
    border: 1px solid #ccc !important;
    filter: none;
}

.pvContainer .image-gallery-icon svg {
    width: 32px;
    height: 32px;
    color: #222;
}

.pvContainer .image-gallery-left-nav {
    margin-inline-start: 15px;
}

.pvContainer .image-gallery-right-nav {
    margin-inline-end: 15px;
}

.pvContainer .image-gallery-left-nav:hover,
.pvContainer .image-gallery-right-nav:hover {
    background: #fff;
}

.pvContainer .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 450px;
    min-height: 450px;
}

.pvContainer.fullPage .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
    min-height: 100%;
}

.pvContainer .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    height: auto;
}

.pvContainer .pvLt .mainSwiper.swiper .swiper-slide {
    height: 450px;
}
.pvContainer .pvLt .mainSwiper.swiper .swiper-slide img {
    width: 100%;
    height: inherit;
    object-position: top;
    object-fit: contain;
    border-radius: 15px;
}

.pvContainer .pvLt .thumbSwiper.swiper .swiper-slide {
    height: 80px;
    width: 80px;
}

.pvContainer .pvLt .thumbSwiper.swiper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    opacity: 0.5;
}
.pvContainer .pvLt .thumbSwiper.swiper .swiper-slide-thumb-active img {
    opacity: 1;
}

.pvContainer .pvLt .thumbSwiper.swiper .swiper-slide {
    border: 1px solid #fff;
}

.pvContainer .pvLt .thumbSwiper.swiper .swiper-slide {
    /* background: #d9e4f2; */
    /* padding: 12px; */
    width: 120px !important;
    height: 120px;
    border-radius: 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.115);
}
.pvContainer .pvLt .thumbSwiper.swiper .swiper-slide-thumb-active {
    border-color: var(--secColor);
}

.pvContainer .pvLt .thumbSwiper {
    /* margin-top: 15px; */
    /* position: absolute; */
    /* bottom: -56px; */
    transform: translateY(-30%);
    padding: 0 22px;
}

.pvContainer .pvLt .mainSwiper .swiper-wrapper {
    /* padding-top: 10px; */
}

/* .pvContainer .pvPrimInfo .buyingCnt .primButton {
    min-width: 120px;
} */

.pvContainer .pvBidStatus h4 {
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin-bottom: 12px;
}

.pvContainer .pvBidStatus .winning {
    background: #6aa73a;
}

.pvContainer .pvBidStatus .won {
    background: #06a2b4;
}

.pvContainer .pvBidStatus .outbid {
    background: #ff9472;
}

.pvContainer .pvBidStatus .lost {
    background: #ff7272;
}

.pvZoomCnt {
    right: 32px;
    margin-top: 16px;
    margin-right: 24px;
    padding: 8px;
    background: white;
    border-radius: 10px;
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

/* Responsive */

@media (max-width: 1280px) {
    .pvContainer .pvPrimInfo {
        grid-template-columns: 100%;
    }

    .pvContainer .pvPrimInfo .biddingCnt {
        /* gap: 10px; */
        justify-content: flex-start !important;
    }
}

@media (max-width: 1250px) {
    .pvContainer .pvPrimInfo .biddingCnt {
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) {
    .pvContainer .pvLt .mainSwiper.swiper .swiper-slide {
        height: 300px;
    }

    .pvContainer .pvLt .thumbSwiper.swiper .swiper-slide {
        height: 40px;
        min-width: 40px;
    }

    .pvContainer .pvLt .thumbSwiper.swiper .swiper-slide img {
        object-fit: cover;
    }

    .pvContainer {
        grid-template-columns: 100%;
    }

    .pvContainer .pvLt {
        position: relative;
        padding: 15px 0;
    }

    .pvContainer .pvRt {
        padding-inline-start: 0;
        padding: 15px 0;
    }

    .pvContainer .pvPrimInfo {
        grid-template-columns: 100%;
        gap: 10px;
    }

    .pvContainer .pvAucDetails {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .pvContainer .pvPrimInfo .biddingCnt {
        /* gap: 10px; */
        flex-wrap: nowrap;
        justify-content: flex-start !important;
    }

    .pvContainer .pvRt .pvTitle {
        font-size: 16px;
    }

    .pvContainer .pvPrimInfo .pvAucDetails h4 {
        font-size: 13px;
    }

    .pvContainer .pvPrimInfo .pvAucDetails h4 > span > span {
        font-size: 20px;
    }

    .pvContainer .qualityRating .pvQuality span,
    .pvContainer .pvDescChild h6,
    .pvContainer .pvDescChild {
        font-size: 13px;
    }

    .pvContainer .pvSubtitle {
        font-size: 16px;
    }

    .pvContainer .pvBidHistory table th,
    .pvContainer .pvBidHistory table td {
        font-size: 13px;
        text-align: left;
    }
    .pvContainer .pvPrimInfo .pvAucDetails {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .pvContainer .pvRt .pvTitleHeading {
        font-size: 22px;
    }
    .pvContainer .pvRt .pvWpriceCnt span {
        font-size: 16px;
    }
    .pvContainer .pvRt .pvPriceBtn .pvBidBox .biddingCnt,
    .pvContainer .pvRt .pvPriceBtn .pvBidBox .buyingCnt {
        align-items: center !important;
    }
}
