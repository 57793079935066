.filterPanel {
    position: sticky;
    top: 15px;
}

/* GRID */
.productCardGrid {
    width: 100%;
    max-width: 380px;
    text-align: left;
    background: #fff;
    margin-bottom: 10px;
    height: auto;
    border: 1px solid var(--accentColorSec);
    border-radius: 20px;
    padding: 16px;
    position: relative;
}
.productCardGrid .pcgImg {
    width: 100%;
    height: 180px;
    margin: 5px 0 12px 0;
    position: relative;
}

.productCardGrid .pcgImg .favoriteCheck {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: fit-content;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
    font-size: 13px;
    min-width: max-content;
    width: 80%;
    border-radius: 20px;
    /* background: var(--primColor); */
    background: var(--secColor);
    color: white;
}

.statusMessage > span {
    font-size: 1rem;
    line-height: 1.2;
    color: var(--primColor) !important;
    vertical-align: middle;
}
.statusMessage > h6 {
    display: inline-block;
    margin-inline-start: 3px;
}

.productCardGrid .pgBidStatus {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    padding: 0 16px;
    right: 0;
}

.productCardGrid .pgBidStatus h4 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 2px 32px;
    height: 32px;
    background: var(--primColor);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}

.productCardGrid .pgBidStatus .winning {
    background: #6aa73a;
    width: 100%;
}

.productCardGrid .pgBidStatus .won {
    background: #06a2b4;
}

.productCardGrid .pgBidStatus .outbid {
    background: #ff9472;
}

.productCardGrid .pgBidStatus .lost {
    background: #ff7272;
}

.productCardGrid .pcgImg img {
    width: 100%;
    height: 100%;
    aspect-ratio: 340 / 180;
    object-fit: contain;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-radius: 15px;
    background: #f6f6f6;
}

.productCardGrid .gridProdTitle {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin: 0 0px 0px;
    padding-top: 0px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

body.arabic .productCardGrid .gridProdTitle,
body.arabic .productCardGrid .prodTitleCndCnt {
    text-align: right;
}

.productCardGrid .gridProdMisc {
    background: #e3eaf2;
    padding: 5px 10px;
    /*! display: flex; */
    /*! justify-content: flex-end; */
    gap: 2px;
}

.productCardGrid .gridProdMisc button,
.productCardGrid .favoriteCheck {
    height: fit-content;
    border-radius: 10px;
    /*! background: #fff; */
    color: #fff;
    padding: 0px;
}

.productCardGrid .favoriteCheck,
.productCardGrid .gridProdMisc button.share {
    width: 35px;
    min-width: initial;
}

.productCardGrid .favoriteCheck label {
    color: #333;
}

.productCardGrid .gridProdMisc button.share .material-icons {
    font-size: 14px;
}

.productCardGrid .gridProdMisc button .MuiButton-label {
    text-transform: initial;
    font-size: 14px;
    width: fit-content;
}

.productCardGrid .gridProdMisc {
    right: 10px !important;
    left: auto !important;
}
.productCardGrid .productGridDetails {
    /*! background: #e3eaf2; */
    margin-top: 18px;
}

.productCardGrid .productGridDetails .pgTimePrice {
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    padding: 6px 12px;
    border-radius: 10px;
    background: #f9fbfd;

    margin-top: 12px;
}

.productCardGrid .productGridDetails .pgTimePrice > div {
    /*! background: #fff; */
    border-radius: 10px;
    text-align: center;
    color: #333;
    padding: 0px;
}
.productCardGrid .productGridDetails .pgTimePrice h6 {
    font-size: 14px;
    /*! text-transform: uppercase; */
    font-weight: 400;
    margin-bottom: 0px;
    width: fit-content;
}
.productCardGrid .productGridDetails .pgTimePrice p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    font-weight: 600;
    width: fit-content;
}
.pgMiscInfoCnt .pgRating {
    display: flex;
    justify-content: center;
    gap: 5px;
    color: #72cf72;
    padding: 25px;
    background: #fff;
}

.pgMiscInfoCnt {
    /* padding: 6px 12px; */
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.pgMiscInfoCnt .MuiInputBase-input {
    width: 100px;
}
.pgMiscInfoCnt button .MuiButton-label {
    font-size: 14px;
}
.pgMiscInfoCnt .MuiFormLabel-root {
    font-size: 12px;
}
.pgMiscInfoCnt .pgBuyNowCnt p {
    font-family: var(--fontFamilySec);
    margin-bottom: 0px;
    font-weight: 600;
}
.pgMiscInfoCnt h6 {
    margin-bottom: 0px;
}

.pgBuyAuctionCnt {
    /* display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; */
    display: grid;
    grid-template-columns: 120px 1fr;
    justify-content: space-between;
    gap: 2px;
    align-items: center;
}

.pgBuyAuctionCnt .customInput {
    margin-bottom: 0px;
}

.pgBuyAuctionCnt p {
    margin-bottom: 0;
    font-weight: 600;
    font-family: var(--fontFamilySec);
}

.pgBuyAuctionCnt .buyingCnt,
.pgBuyAuctionCnt .biddingCnt {
    margin-top: 0px !important;
    gap: 4px;
    align-items: center !important;
}

.pgBuyAuctionCnt .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.pgBuyAuctionCnt .MuiOutlinedInput-root {
    background: #f9fbfd;
}
.pgBuyAuctionCnt .MuiOutlinedInput-root input::placeholder {
    font-size: 11px;
}
.pgBuyAuctionCnt h6 {
    font-size: 14px;
    font-weight: 400;
}
.pgBuyAuctionCnt .MuiFormHelperText-root.Mui-error {
    font-size: 10px;
    bottom: -16px;
    position: absolute;
    width: 100%;
}
.pgBuyAuctionCnt .primButton {
    width: 100%;
}

.pgBuyAuctionCnt .primButton button,
.pgBuyAuctionCnt .biddingCnt .primButton button {
    width: 100% !important;
    padding: 6px 12px;
}

.productCardGrid .pgMiscInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    text-align: center;
    background: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 12px 10px;
    border-bottom: 1px solid #eaeaea;
}

.productCardGrid .pgMiscInfo h6 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 2px;
}

.productCardGrid .pgMiscInfo p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.productCardGrid .gridLotInfo {
    margin-bottom: 5px;
}

.productCardGrid .gridLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
}

.productCardGrid .gridLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridLotDetails p {
    font-size: 13px;
    color: var(--secColor);
    font-weight: 600;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 5ex;
}

.productCardGrid .gridTimer h6,
.productCardGrid .gridBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
}

.productCardGrid .gridTimer p,
.productCardGrid .gridBidInfo p {
    margin: 0;
    font-size: 14px;
    color: #333;
    font-weight: 600;
}

.productCardGrid .gridBidInfo p span.gridDivider {
    padding: 0 15px;
}

.productCardGrid .gridBidInfo {
    margin-top: 5px;
    height: 4.5ex;
}

.productCardGrid .gridTimer p span {
    padding-inline-end: 5px;
}

.productCardGrid .gridBidBox {
    margin-top: 0;
    background: #fff;
    /* padding: 10px; */
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    width: 100%;
    margin-inline-start: 0;
    font-size: 12px;
    margin-top: 0px;
}

.productCardGrid .gridBidBox .customInput {
    margin: 0;
    margin-inline-end: 5px;
    width: 100%;
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root {
    background: #fff;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.productCardGrid .gridBidBox .primButton {
    /* width: 49%; */
    width: auto;
}

.productCardGrid .moreInfo button {
    color: var(--primColor);
    text-transform: initial;
}

.productCardGrid .returnItemAct .MuiFormGroup-root .MuiFormControlLabel-root {
    width: 100%;
    margin: 0;
}

.productCardGrid
    .returnItemAct
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiButtonBase-root
    ~ .MuiFormControlLabel-label {
    border: 1px solid var(--primColor);
    background: #fff;
    text-transform: uppercase;
    color: var(--primColor);
    border-radius: 3px;
}

.productCardGrid
    .returnItemAct
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiButtonBase-root.Mui-checked
    ~ .MuiFormControlLabel-label {
    background: var(--primColor);
    color: #fff;
}

.productCardGrid .returnItemAct .MuiFormGroup-root .MuiCheckbox-root {
    display: none;
}

.productCardGrid
    .returnItemAct
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 15px;
    width: 100%;
}

.productCardGrid
    .returnItemAct
    .MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiFormControlLabel-label
    .MuiListItem-button {
    justify-content: center;
}

/* GRID END */

/* SPECIAL EVENT */

.specialEvent {
    background: #fff;
    padding: 16px;
    /* max-width: 340px; */
    text-align: left;
    margin-bottom: 10px;
    height: auto;
    border-radius: 20px;
    border: 1px solid var(--accentColorSec);
}

.specialEvent .primButton button {
    background: var(--secColor);
    border-radius: 20px;
    color: white;
    border: 0;
}
.specialEvent .primButton button:hover {
    background: var(--secColor) !important;
    border: 0;
}

.specialEvent .seImg {
    width: 100%;
    height: 160px;
    position: relative;
    margin-inline-end: 0;
    background: #f6f6f6;
    border-radius: 15px;
}

.specialEvent .seImg img {
    width: 100%;
    height: 100%;
    aspect-ratio: 300 / 250;
    object-fit: cover;
    object-position: top;
    border-radius: inherit;
}

.auctionTitleCndCnt {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}
.auctionTitleCndCnt p,
.auctionTitleCndCnt h2 {
    margin: 0;
}

.auctionCndContainer > p {
    width: 100%;
    height: 100%;
    padding: 6px 10px;
    background: var(--primColor);
    font-size: 14px;
    border-radius: 10px;
}
.specialEvent .seTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    /* margin: 15px 0 15px; */
    color: var(--secColor);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65%;
    border-radius: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

body.arabic .specialEvent .seTitle {
    text-align: right;
}

.specialEvent .listTimer {
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 10px;
    display: flex;
    padding: 6px 12px;
    margin-top: 12px;
    justify-content: space-between;
    background: #f9fbfd;
    border-radius: 10px;
}
.specialEvent .listTimer span {
    margin-bottom: 0px;
    font-size: 14px;
}
.specialEvent .listTimer p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
}

.specialEvent .listTimer .material-icons-outlined {
    margin-inline-end: 0px;
}

.specialEventDrawer .calItems {
    padding-bottom: 20px;
}

.specialEventDrawer .calItems h3 {
    font-size: 14px;
    color: var(--secColor);
    font-weight: 700;
    padding: 25px 16px 5px;
    text-align: left;
    text-transform: uppercase;
}

.specialEventDrawer .calItems .ciIcon {
    padding-inline-end: 15px;
}

.specialEventDrawer .MuiPaper-root.MuiDrawer-paper {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.specialEvent .seActBtn {
    width: 100%;
}

.specialEvent .seActBtn .atcBtn {
    padding-inline-start: 0;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root,
.specialEvent .MuiFab-primary {
    color: var(--primColor);
    text-transform: initial;
    font-size: 14px;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root:first-child {
    margin-top: 5px;
    padding-inline-start: 0;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root i,
.specialEvent .MuiFab-primary i {
    /* padding-inline-end: 10px; */
}

.specialEvent .MuiFab-primary {
    background-color: var(--secColor);
    box-shadow: none;
    width: max-content;
    margin: auto;
    border-radius: 15px;
    height: auto;
    /* flex-shrink: 0; */
    min-width: max-content;
}
/* 
.specialEvent .MuiFab-primary:hover {
    background-color: #fff;
    box-shadow: none;
} */

.specialEvent .MuiSpeedDial-directionLeft {
    flex-direction: row;
    padding: 6px 8px;
    height: 36px;
    position: relative;
}

.specialEvent .MuiSpeedDial-actions {
    position: absolute;
    /* padding: 6px 8px; */
    /* left: 50px; */
    transform: translateY(100%);
}
.seLinks {
    width: max-content;
    /* padding: 8px 12px; */
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    /* background: var(--secColor); */
    /* border-radius: 8px; */
    position: absolute;
    top: 10px;
    left: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    right: 10px !important;
    left: auto !important;
}

.seLinks .MuiFab-label {
    padding: 8px 12px;
}
.seLinks .MuiSpeedDial-directionUp .MuiSpeedDial-actions {
    margin-bottom: 0px !important;
}
.seLinks .MuiFab-label img {
    width: 20px;
    height: 20px;
}

.specialEvent .MuiFab-primary:hover {
    background-color: var(--secColor);
}

/* SPECIAL EVENT END */

/* LIST */

.productCardList {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.productCardList .psListLt {
    width: 70%;
}

.productCardList .pclImg {
    width: 100%;
    max-width: 250px;
    position: relative;
    margin-inline-end: 20px;
}

.productCardList .pclImg img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 3px;
}

.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 0;
    left: 0;
}

.productCardList .listContent {
    width: max-content;
    text-align: left;
    margin-inline-end: 20px;
}

.productCardList .listActBtn {
    width: 30%;
    max-width: 280px;
}

.productCardList .listActBtn .customInput {
    width: 40%;
}

.productCardList .listActBtn .primButton,
.productCardList .listActBtn .secButton {
    width: 58%;
}

.productCardList .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.productCardList .listContent .listLotInfo {
    margin: 5px 0;
}

.productCardList .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.productCardList .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardList .listActBox {
    width: 30%;
}

.productCardList p {
    font-size: 14px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.productCardList .listTimer h6,
.productCardList .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.productCardList .listTimer p,
.productCardList .listBidInfo p {
    margin: 0;
    font-size: 14px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.productCardList .listBidInfo {
    margin-top: 5px;
}

.productCardList .listTimer p span {
    padding-inline-end: 5px;
}

.productCardList .listBidBox {
    margin: 15px 0;
}

.productCardList .listBidBox .customInput {
    margin: 0;
}

.productCardList button.bidDetails {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* LIST END */

/* CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.cartItem .itemInfo {
    width: calc(100% - 25%);
}

.cartItem .pclImg {
    width: 100%;
    max-width: 200px;
    position: relative;
    margin-inline-end: 20px;
}

.cartItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    border-radius: 3px;
}

.cartItem .listContent {
    width: max-content;
    text-align: left;
    margin-inline-end: 20px;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.cartItem .listContent .listLotInfo {
    gap: 30px;
    row-gap: 0;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-inline-end: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-inline-end: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: left;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 15px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 460px;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

.specialEventSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.3fr 3fr 1.7fr;
    margin: 15px 0;
    background: #f7f7f7;
}

.specialEventSkeleton .skeleton.gridImage {
    height: 120px;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: left;
    white-space: nowrap;
}

.updateCartQuantity {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.updateCartQuantity .customInput {
    margin-bottom: 0;
    width: 100px;
}

.updateCartQuantity .customInput .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
}

.updateCartQuantity .customInput input {
    text-align: center;
}

.updateCartQuantity button {
    width: 40px;
    height: 40px;
    min-width: initial;
    color: var(--primColor);
}

/* @media (min-width: 1400px) {
    .productCardGrid {
        width: 32%;
    }
} */

/* @media (min-width: 1900px) {
    .productCardGrid {
        width: 23%;
    }
} */

.pgShare .MuiPaper-root.MuiMenu-paper {
    min-width: 170px;
}

.pgShare .copyClipboard {
    padding: 0;
}

.pgShare .MuiButtonBase-root {
    width: 100%;
}

.pgShare .MuiButtonBase-root .MuiButton-label {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.pgShare .MuiButtonBase-root .react-share__ShareButton {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.pgShare .copyClipboard .MuiButton-label {
    text-transform: initial;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: initial;
}

.pgShare .copyClipboard .MuiButton-label .material-icons {
    padding-left: 10px;
    font-size: 20px;
}

body.arabic .pgShare .copyClipboard .MuiButton-label .material-icons {
    padding-left: 0;
    padding-right: 0;
}

.pgShare .react-share__ShareButton .MuiButton-label {
    width: 100%;
}

.pgShare .react-share__ShareButton span {
    padding-left: 10px;
    font-size: 20px;
    color: #316ff6;
}

body.arabic .pgShare .react-share__ShareButton span {
    padding-left: 0;
    padding-right: 10px;
}

.productCardGrid .gridBidBox .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 13px;
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    width: 100%;
    margin-inline-start: 0;
}

.productCardGrid .gridBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}
.productCardGrid .gridBidBox .buyingCnt > div {
    margin: 0;
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .customInput {
    margin-bottom: 0;
}
.productCardGrid .gridBidBox .buyingCnt .customInput {
    margin-bottom: 0;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.productCardGrid .gridBidBox .biddingCnt .primButton,
.productCardGrid .gridBidBox .biddingCnt .secButton {
    width: 49%;
}

.productCardGrid .gridBidBox .buyingCnt .primButton,
.productCardGrid .gridBidBox .buyingCnt .secButton {
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .primButton:only-child,
.productCardGrid .gridBidBox .biddingCnt .secButton:only-child {
    width: 100%;
}

.productCardGrid .notesCnt,
.pvTop .notesCnt {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 12px;
    text-align: center;
    z-index: 1;
}

.productCardGrid .notesCnt div {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
}
.productCardGrid .notesCnt div .material-icons {
    cursor: pointer;
}

.productCardGrid .notesCnt.show {
    visibility: visible;
    opacity: 1;
}

.productCardGrid .notesCnt p {
    margin: 0;
    font-size: 15px;
}
.productCardGrid .pcgPoints,
.productCardGrid .gridProdMisc {
    width: max-content;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: var(--secColor);
    border-radius: 8px;
    position: absolute;
    top: 10px;
    left: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.productTitleCndCnt h1 {
    font-size: 16px;
    font-weight: 500;
}

.prodCndCnt > p {
    margin-bottom: 0px;
    padding: 12px 16px;
    background: var(--accentColorSec);
    border-radius: 10px;
    font-size: 14px;
}

.prodTitleCndCnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
}
.prodTitleCndCnt h1 {
    font-size: 16px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.prodNumBidCnt {
    display: flex;
}

.pgTimePrice div,
.pgTimePrice .prodNumBidCnt {
    display: flex;
    align-items: center;
}
.pgTimePrice > div:nth-child(1) {
    flex-direction: column;
}

.pgTimePrice .prodNumBidCnt {
    gap: 16px;
}
.pgTimePrice .prodNumBidCnt .cursorDecoy {
    font-size: 16px !important;
}

button.notes,
button.share {
    min-width: fit-content !important;
    width: fit-content !important;
}
.notes .material-icons {
    font-size: 16px;
}

.seLinks .atcBtn {
    background: var(--secColor);
    width: max-content;
    border-radius: 15px;
    padding: 0;
    min-width: 0 !important;
}

.seLinks .atcBtn:hover {
    background: var(--secColor);
}

.seLinks .atcBtn .MuiButton-label {
    padding: 8px 12px;
    width: fit-content;
}
.seLinks .atcBtn .MuiButton-label .fa-calendar-plus {
    color: var(--primColor);
    font-size: 14px;
}

/* Responsive */

@media (max-width: 768px) {
    .productCardGrid {
        max-width: 100%;
    }
    .cartItem {
        flex-wrap: wrap;
        width: 100%;
    }
    .cartItem .itemInfo {
        width: 100% !important;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none !important;
        margin-inline-end: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }
    .cartItem .listContent {
        width: 100%;
        margin-inline-end: 0;
    }
    .cartItem .listActBtn {
        width: 100% !important;
    }
    .cartItem .listBidInfo p {
        justify-content: flex-start !important;
    }
    .cartItem button.removeCart {
        width: auto;
        float: left;
    }
    .cartItem .listContent .listLotInfo h5 {
        width: 100%;
    }
    .cartItem .listContent .listLotInfo {
        flex-wrap: wrap;
    }

    .cart .cartLt .cartInnerCnt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 15px;
    }
    .cartItem .listActBtn {
        flex-wrap: wrap;
    }
    .pgBuyAuctionCnt {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 500px) {
    .productCardGrid {
        padding: 12px;
    }
    .pgMiscInfoCnt .MuiFormLabel-root {
        font-size: 12px !important;
    }
    .pgMiscInfoCnt button .MuiButton-label {
        font-size: 12px;
    }
    .pgMiscInfoCnt .pgBuyNowCnt p {
        font-size: 14px;
    }
    .pgBuyAuctionCnt h6 {
        font-size: 12px;
    }
    .productCardGrid .gridProdMisc button .MuiButton-label {
        font-size: 12px;
    }
    .productCardGrid .pcgPoints,
    .productCardGrid .gridProdMisc {
        font-size: 12px;
    }
    .productCardGrid .productGridDetails .pgTimePrice > div {
        align-items: center;
    }
    .prodCndCnt > p {
        font-size: 12px;
    }
    .prodTitleCndCnt h1 {
        font-size: 14px;
    }

    .pgMiscInfoCnt .MuiInputBase-input {
        /* width: 75px; */
    }
    .pgMiscInfoCnt .pgRating {
        padding: 10px;
    }
    .productCardGrid .productGridDetails .pgTimePrice h6 {
        /* margin-bottom: 5px; */
        font-size: 12px;
    }
    .productCardGrid .productGridDetails .pgTimePrice p {
        font-size: 12px !important;
    }
    .specialEvent .listTimer {
        font-size: 13px;
    }
    .specialEvent .seTitle {
        font-size: 15px;
    }
    .specialEvent .seContent h2 {
        font-size: 16px;
        line-height: 20px;
    }
    .specialEvent .seContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }
    .cartItem .itemInfo {
        width: 100%;
    }
    .productCardGrid .gridProdTitle {
        font-size: 14px;
    }
    .productCardGrid .gridTimer h6,
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }
    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }
    .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    }
    /* .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        width: 49%; */
    /* } */
    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }
    .similarItem .pclImg {
        width: 30%;
        margin-inline-end: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cart.buyNowCart .cartItem .listActBtn {
        justify-content: center !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }
}

@media (max-width: 450px) {
    .specialEvent .seContent h2 {
        font-size: 14px;
    }
    .specialEvent .seContent p {
        font-size: 12px;
    }
    .specialEvent .seContent {
        margin-bottom: 10px;
    }
    .cartItem .listBidInfo {
        width: 100%;
    }
}
