.auctionList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 15px;
}

.auctionListCnt .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.auctionListCnt .alTitle {
    width: max-content;
    text-align: left;
}

.auctionListCnt .alTitle h2 {
    font-size: 26px;
    color: #353535;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
}

.auctionListCnt .searchHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

@media (max-width: 1100px) {
    .auctionList {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

@media (max-width: 991px) {
    .auctionList {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 600px) {
    .auctionListCnt .searchHead {
        flex-wrap: wrap;
    }

    .auctionListCnt .searchHead .alTitle {
        width: 100%;
    }
    .auctionListCnt .searchMiscFilters {
        display: grid !important;
        margin-top: 15px;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    .auctionListCnt .searchMiscFilters .customSelect {
        margin: 0;
    }
    .auctionListCnt .alTitle {
        text-align: center;
    }
    .auctionListCnt .alTitle h2 {
        font-size: 20px;
    }
    .auctionListCnt .alTitle h6 {
        font-size: 15px;
    }
}
