.search {
    background: var(--backgroundColor);
    padding-top: 10px;
    padding-bottom: 30px;
}

.search .sectionTitle .savedSearchInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px dashed #c1c1c1; */
    padding: 5px 15px;
    border-radius: 8px;
    margin: 10px 0;
    box-shadow: 0px 3px 16px #e0dede;
}

.search .sectionTitle .searchInfo h6 {
    font-size: 14px;
    color: 14px;
    font-weight: 600;
    color: #999999;
}

.search .sectionTitle .savedSearchInfo p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.search .sectionTitle .savedSearchInfo p span {
    font-weight: 700;
}

.search .sectionTitle .savedSearchInfo .MuiButton-root .material-icons {
    margin-right: 5px;
}

.search .sectionTitle .savedSearchInfo .MuiButton-root .MuiButton-label {
    text-transform: initial;
    font-size: 15px;
    color: var(--primColor);
    letter-spacing: initial;
}

.search .sectionTitle .searchInfo h6:first-child {
    color: var(--primColor);
    margin-inline-end: 30px;
}

.search .searchResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 15px;
    row-gap: 25px;
    margin: 0;
}

.search .searchLt .customSelect {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.search .searchLt .gridListToggle button {
    color: var(--secColor);
    width: 50%;
}

.search .searchLt .gridListToggle button.active {
    color: var(--primColor);
    background: #fff;
}

.search .searchLt .gridListToggle i {
    padding-inline-end: 10px;
}

.search .searchInput input::-webkit-input-placeholder {
    color: #b7b7b7;
}
.search .searchInput input:focus::-webkit-input-placeholder {
    color: #464646;
}

/* Firefox < 19 */
.search .searchInput input:-moz-placeholder {
    color: #b7b7b7;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
    color: #464646;
}

/* Firefox > 19 */
.search .searchInput input::-moz-placeholder {
    color: #b7b7b7;
}
.search .searchInput input:focus::-moz-placeholder {
    color: #464646;
}

/* Internet Explorer 10 */
.search .searchInput input:-ms-input-placeholder {
    color: #b7b7b7;
}
.search .searchInput input:focus:-ms-input-placeholder {
    color: #464646;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 50vmax;
    max-width: 800px;
    padding: 20px;
}

.searchSkeletonBody {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.search .toggleRespDrawer {
    display: none;
}

.search .searchLt .searchLtHeading {
    text-align: left;
    font-size: 20px;
    margin-bottom: 32px;
}

.search .searchBannerTitleCnt {
    margin-bottom: 80px;
}
.searchLt .customSelect {
    margin-left: 8px;
}

.searchLt .MuiOutlinedInput-notchedOutline {
    /* border: 0; */
    border-radius: 20px;
    border: 1px solid var(--accentColorPrim);
}
.searchLt .MuiSelect-select.MuiSelect-select {
    background: #f9fbfd;
    font-size: 14px;
    padding: 17px 32px 17px 24px;
    color: var(--secColor);
    border-radius: 12px;
}
.searchLt .MuiInputLabel-outlined {
    font-size: 13px;

    color: var(--accentColorPrim);
}
.searchLt .MuiFormLabel-root.Mui-focused {
    color: var(--accentColorPrim) !important;
}

.searchLt .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
}

.searchLt .MuiSelect-icon {
    color: var(--accentColorPrim);
}

@media (max-width: 1024px) {
    .search .toggleRespDrawer {
        display: inline-flex;
    }
    .home .hmSearch .searchInput,
    .search .searchInput {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .searchLt {
        display: none;
    }

    .sbTitle {
        font-size: 14px;
        color: var(--secColor);
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .searchAuctions {
        margin-bottom: 20px;
    }

    .searchAuctions label,
    .searchAuctions button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        color: rgba(5, 21, 39, 0.7);
        height: 50px;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        border: 1px solid #d8d8d8;
        border-radius: 25px;
        margin-inline-end: 10px;
        text-decoration: none;
        transition: 200ms all ease-in-out;
        font-size: 16px;
        font-weight: 600;
        text-transform: initial;
        padding: 5px 15px;
        margin-bottom: 10px;
        height: 40px;
    }

    .searchAuctions button.active {
        background: rgb(229 237 248);
        color: var(--secColor);
        border: 1px solid var(--secColor);
    }

    .searchAuctions button .MuiButton-label {
        font-size: 13px;
    }

    .searchAuctions input[type='radio']:checked + label {
        background: rgb(249 238 236);
        color: var(--primColor);
        border: 1px solid var(--primColor);
    }
}

@media (max-width: 600px) {
    .search .sectionTitle {
        margin-bottom: 15px;
    }
    .searchAuctions label {
        height: 40px;
        font-size: 13px;
    }

    .search .searchCnt {
        margin-top: 0;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }

    .productView .pvActBtn {
        flex-wrap: wrap;
    }
    .productView .pvActBtn .pabChild:first-child {
        width: 100%;
    }
    .search .sectionTitle .searchInfo h6:first-child {
        display: none;
    }
}

@media (max-width: 500px) {
    .search .sectionTitle .searchInfo h6 {
        font-size: 12px;
    }
}
